import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { Card } from 'react-bootstrap';
import boxImg from '../box.png';
import SETTINGS from '../SETTINGS';

const NodeType = 'NODE';
const DraggableNode = ({ nft, onClick, isNode }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: NodeType,
    item: { id: nft.tokenId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  drag(ref);

  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, marginBottom: '1rem' }}
      className='card-fix'
      onClick={() => onClick(nft)}
    >
      <Card>
      {isNode &&(
        <div style={{ marginBottom: '20px' }}>
        <div className='tokenCardId' ># {nft.tokenId}</div>   
        </div>                 
      )}
        <Card.Body className='card-node' >
    
          <Card.Img variant="top" src={nft.imageURL || boxImg} alt={`NODE ${nft.tokenId}`} className='height80' />
          <center>
            <small>{SETTINGS.NODE_INFO[nft.tokenType].title}</small>
            </center>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DraggableNode;
