import React from 'react';
import { Card } from 'react-bootstrap';

const NodeCard = ({ id, name, imageURL, unclaimedRewards }) => {
  return (
    <div className="mb-2" style={{ width: "100%", left: "0%" }}>
      <Card.Img variant="top" src={imageURL} alt={`QWRLD ${id}`} style={{ height: "100px" }} />
    </div>
  );
};

export default NodeCard;
