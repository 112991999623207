import React, { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const CustomToast = ({ show, message, type, onClose }) => {
  const [position, setPosition] = useState('bottom-end');
  return (
    <ToastContainer position="bottom-end" className="p-3">
      <Toast position={position} show={show} onClose={onClose} bg={type} delay={6000} autohide>
        <Toast.Header>

          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

const useCustomToast = () => {
  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: 'success', // success, danger, info, warning
  });

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast({ ...toast, show: false });
  };

  return { toast, showToast, hideToast };
};

export { CustomToast, useCustomToast };
