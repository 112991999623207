import React, { useState, useEffect } from "react";
import { BiInfoCircle } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";

const Helper = ({message, link}) => {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
      };
    const handleCloseModal = () => {
        setShowModal(false);
      };


  return (
    <div>
      <button className="infoButton" onClick={handleShowModal} >
                       <BiInfoCircle />
                          </button>
                          <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Info</Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
                    <p style={{fontSize:15, padding:10, lineHeight:"26px", textAlign:"justify", backgroundColor:"#FFFFFF", padding:10, borderRadius:20}}>{message}
                    <br />
                    <br />
                    <a href={link} target="_blank">Learn more</a>
                    </p>
                    
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

    </div>
  );
};

export default Helper;
