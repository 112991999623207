import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Button, Spinner } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits, parseEther } from 'ethers';
import StakingABI from '../abis/StakingABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.svg';
import { CustomToast, useCustomToast } from '../components/CustomToast';

const STAKE_DURATIONS = {
    "FIRST": [30 * 86400, 50, 15],       // 30 days, 1.0% per day
    "SECOND": [45 * 86400, 100, 45],      // 45 days, 1.5% per day
    "THIRD": [90 * 86400, 150, 135],       // 90 days, 2.0% per day
    "FOURTH": [270 * 86400, 200, 540],    // 270 days, 2.5% per day
    "FIFTH": [540 * 86400, 250, 1350],     // 540 days, 3.0% per day
    "SIXTH": [810 * 86400, 300, 2430],     // 810 days, 3.5% per day
};
const Staking = ({ provider, account, isConnected, networkId, switchNetwork }) => {
    const [tokenBalance, setTokenBalance] = useState('0');
    const [stakes, setStakes] = useState([]);
    const [staked, setStaked] = useState(0);
    const [contractBalance, setContractBalance] = useState(0);
    const [amountToStake, setAmountToStake] = useState('');
    const [durationToStake, setDurationToStake] = useState("FIRST");
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const { toast, showToast, hideToast } = useCustomToast();
    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

        const ContractBalance = await TokenContract.balanceOf(SETTINGS.stakingContract);
        setContractBalance(formatUnits(ContractBalance, TokenDecimals));
        
    }

    const getStakeDurationName = (firstValue) => {
        for (const [key, value] of Object.entries(STAKE_DURATIONS)) {
            if (value[0] === firstValue) {
                return key;
            }
        }
        return null; // or some default value
    };
    useEffect(() => {
        if (!provider || !account) return;
        
        fetchStakes();
        getBalance();
    }, [provider, account, networkId]);
    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            const stakedAm = await contract.staked();
            setStaked(formatUnits(stakedAm, 18));
            const stakesDataUser = await contract.getUserStakes(account);
            const serialized = JSON.stringify(stakesDataUser, (key, value) =>
                typeof value === 'bigint' ? value.toString() : value
            );
            const stakesData = JSON.parse(serialized);
            let stakesArr = [];
            for (let i = 0; i < stakesData.length; i++) {
                const stake = stakesData[i];
                const rewardPercent = STAKE_DURATIONS[getStakeDurationName(parseInt(stake["2"]))][1];
                const amount = formatUnits(stake["0"].toString(), 18);
                const rewardAmount = parseFloat(amount) + (parseFloat(amount) / 100) * rewardPercent;
                if (!stake["3"]) {
                    stakesArr.push({
                        amount: amount,
                        rewardAmount: rewardAmount,
                        timestamp: stake["1"],
                        duration: stake["2"],
                        claimed: stake["3"],
                        i
                    });
                }
            }
            setStakes(stakesArr);
            console.log(stakesArr);
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };

    const handleStake = async () => {

        if (!provider || !account) return;
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            setLoading(true);

            const tokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
            const waveAllowance = await tokenContract.allowance(account, SETTINGS.nodeContract);
            const amountToStakeWei = parseUnits(amountToStake.toString(), 18);
            if (amountToStakeWei > waveAllowance) {
                setTxMessage("Approving " + SETTINGS.tokenSymbol + " transaction...");
                const approveTx = await tokenContract.approve(SETTINGS.stakingContract, amountToStakeWei);
                await approveTx.wait();
            }
        
            
            setTxMessage("Staking " + SETTINGS.tokenSymbol + " tokens...");
            const stakingContract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            const stakeTx = await stakingContract.stake(amountToStakeWei, STAKE_DURATIONS[durationToStake][0],{
                value: parseEther("1")
              });
            await stakeTx.wait();
            setTxMessage("" + SETTINGS.tokenSymbol + " staked successfully!");
            await fetchStakes();
            await getBalance();
            setAmountToStake('');
            showToast("Stake successfull!", "success");
        } catch (error) {
            console.error("Staking failed", error);
            showToast("Staking failed. Please try again.", "danger");
            setTxMessage("Staking failed. Please try again.");
        } finally {
            setLoading(false);
            
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    const handleClaim = async (stakeIndex) => {
        if (!provider || !account) return;
        try {
            setLoading(true);
            setTxMessage("Claiming rewards...");
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            await contract.claim(stakeIndex,{
                value: parseEther("1")
              });
            setTxMessage("Rewards successfully claimed!");
            await fetchStakes();
        } catch (error) {
            console.error("Claiming failed", error);
            showToast("Claiming failed. Please try again.", "danger");
            setTxMessage("Claiming failed. Please try again.");
        } finally {
            setLoading(false);
            showToast("Claim successfull!", "success");
            setTimeout(() => setTxMessage(""), 5000);

        }
    };

    const getProgress = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const progress = (timePassed / duration) * 100;
        return Math.min(progress, 100);
    };

    const getTimeLeft = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const timeLeft = duration - timePassed;

        if (timeLeft <= 0) {
            return "Time is up";
        }

        const days = Math.floor(timeLeft / (24 * 3600));
        const hours = Math.floor((timeLeft % (24 * 3600)) / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);

        return `${days} days, ${hours} hours, and ${minutes} minutes left`;
    };

    const isClaimable = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        return (now - timestamp) >= duration;
    };

    const calculateReward = (amount, duration) => {
        const rewardPercent = STAKE_DURATIONS[duration][1];
        return (parseFloat(amount) / 100) * rewardPercent;
    };

    const getAPY = (duration) => {
        return STAKE_DURATIONS[duration][2];
    };

    const isStakeButtonDisabled = () => {
        if (isNaN(parseFloat(amountToStake)) || amountToStake <= 0) {
            return true;
        }
        return false;
    };


    const handleSwitchNetwork = async () => {
        await switchNetwork(137);
       
    };

    if (networkId != "137" && !SETTINGS.IS_TEST)  {
        return (
            <div className=" text-center">
                <div className="network-switch-section" style={{marginTop:"150px"}}>
                 
                      <p>Switch to Polygon network:</p>
                      <Button className="refresh-button hero-button " variant="primary" onClick={handleSwitchNetwork}>
                        Switch to Polygon Network</Button>
                    </div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div className="page-container">
            <CustomToast show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} />
            <h2 className="pageTitle supplies-header">WAVE Stake</h2>
            <p className="hero-p sub-header">Boost your assets with WAVE staking options, designed for maximum flexibility and consistent rewards.</p>
            <Row className='pt-0'>
                <Col sm={12} md={8} lg={6} className='offset-lg-0 offset-md-2 offset-0'>
                <div className="swap-form chat-message  pokemon-card additional-p">
                        <div className="buy-token-header justify-content-center text-center">
                        <h4 className="swap-form-header">Open New WAVE Stake</h4>
                        </div>
                        <div className='mt-4 mb-3 d-flex flex-wrap align-items-center'>
                            <span className='mr-2'>Available staking rewards:</span>
                            <div className='me-md-2 me-0 ms-sm-2 ms-0'>
                                <img
                                    src={logoToken}
                                    className='tokenIconColor'
                                    style={{ width: '18px', marginRight: '6px', marginTop: '-2px' }}
                                    alt={SETTINGS.tokenSymbol}
                                />
                                <b>
                                    {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }).format(contractBalance - staked)}{' '}
                                    {SETTINGS.tokenSymbol}
                                </b>
                            </div>
                        </div>
                        <div className="input-group mt-2" style={{ marginBottom: "14px" }}>
                            <p className='form-label'>Enter amount:</p>
                            <input
                                type="number"
                                value={amountToStake}
                                onChange={(e) => setAmountToStake(e.target.value)}
                                placeholder="Amount to stake"
                                className='custom-select w-100'
                                style={{ fontSize: '14px', padding: '10px' }} // Ensure readability and proper padding
                            />
                            <center>
                                <span style={{ marginTop: "6px", marginBottom: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", fontWeight: "600" }}>
                                    <small>Your balance:</small>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "20px", marginRight: "6px", marginLeft: "10px" }} alt={SETTINGS.tokenSymbol} />
                                        <small>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</small>
                                    </div>
                                    <div>
                                    <Button variant="primary" onClick={(e) => setAmountToStake(parseFloat(tokenBalance)/4)} className='buton smallBtn d-sm-block  mb-1' style={{ fontSize: "12px", width: "70px", padding: "3px 5px", marginLeft: "10px" }}>
                                        25%
                                    </Button>
                                    <Button variant="primary" onClick={(e) => setAmountToStake(parseFloat(tokenBalance)/2)} className='buton smallBtn d-sm-block  mb-1' style={{ fontSize: "12px", width: "70px", padding: "3px 5px", marginLeft: "10px" }}>
                                        50%
                                    </Button>
                                    <Button variant="primary" onClick={(e) => setAmountToStake(parseFloat(tokenBalance)-(parseFloat(tokenBalance)/4))} className='buton smallBtn d-sm-block  ms-2' style={{ fontSize: "12px", width: "70px", padding: "3px 5px", marginLeft: "10px" }}>
                                        75%
                                    </Button>
                                    <Button variant="primary" onClick={(e) => setAmountToStake(tokenBalance)} className='buton smallBtn d-sm-block ms-2' style={{ fontSize: "12px", width: "70px", padding: "3px 5px", marginLeft: "10px" }}>
                                        100%
                                    </Button>
                                    </div>
                                </span>
                            </center>
                        </div>

                        <div className="input-group mb-3 pb-1">
                            <p className='form-label'>Select duration:</p>
                            <select
                                value={durationToStake}
                                onChange={(e) => setDurationToStake(e.target.value)}
                                className='custom-select w-100'
                                style={{ fontSize: '14px', padding: '10px', zIndex: '1050', position: 'relative' }}
                            >
                                <option value="FIRST">30 Days (15%)</option>
                                <option value="SECOND">45 Days (45%)</option>
                                <option value="THIRD">90 Days (135%)</option>
                                <option value="FOURTH">270 Days (540%)</option>
                                <option value="FIFTH">540 Days (1350%)</option>
                                <option value="SIXTH">810 Days (2430%)</option>
                            </select>
                        </div>

                        
                        {/* Info */}
                        <div className="pokemon-card-footer pt-0">
                            <Row className="mb-4" style={{ fontSize: "14px" }}>
                                <div className="d-flex align-items-center mt-1">
                                    <p className='mb-0'><b>Stake size:</b></p>
                                    <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "6px", marginLeft: "7px" }} alt={SETTINGS.tokenSymbol} />
                                    <b className="ml-1">
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(amountToStake) + calculateReward(amountToStake, durationToStake))} </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>

                                <div className="d-flex align-items-center mt-1 small">
                                    <p className='mb-0'>You are staking:</p>
                                    <b className="ms-2" style={{ fontWeight: "400" }}>
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amountToStake)}  </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>
                                <div className="d-flex align-items-center small">
                                    <p className='mb-0'>Stake reward:</p>
                                    <b className="ms-2" style={{ fontWeight: "400" }}>
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculateReward(amountToStake, durationToStake))}  </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>
                          

                            </Row>
                        </div>
                        
                        {/* Button */}
                        <small
                      className="smallText text-left"
                      style={{ paddingBottom: "10px" }}
                    >
                      By clicking the button "Stake WAVE", you agree with
                      WaveSwaps{" "}
                      <a
                        href="https://docs.waveswaps.com/corporate/terms-and-conditions"
                        target="_blank"
                        className='terms-link'
                      >
                        Terms and Conditions.
                      </a>
                      <hr />
                    </small>
                        <Button
                            variant="success"
                            className='button-center'
                            onClick={handleStake}
                            disabled={isStakeButtonDisabled()}
                        >
                            Stake {SETTINGS.tokenSymbol}
                        </Button>
                        {isStakeButtonDisabled() && (
                            <>
                                {!isNaN(parseFloat(amountToStake)) && (
                                    <div className="alert alert-warning mt-3" role="alert">
                                        {parseFloat(tokenBalance) <= parseFloat(amountToStake) ? (
                                            <>Insufficient balance to stake this amount.</>
                                        ) : (
                                            <>Insufficient tokens in the reward pool to cover this stake.</>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Col>

                <Col sm={12} md={12} lg={1} style={{ maxWidth: "40px" }}></Col>

                {/* Your stakes */}
                <Col sm={12} md={8} lg={5} className='offset-lg-0 offset-md-2 offset-0 mb-3'>
                    {stakes.length > 0 && <h4 className='pt-lg-2 pt-4 mb-3 h5'>Your Stakes</h4>}
                    {stakes.map((stake, index) => (
                        <div key={index} className="mb-3 pokemon-card additional-p">
                            <Row>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Stake size:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.rewardAmount)}  {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Staked:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)} {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                            </Row>
                            <p className='mb-2 mt-3 p small'>Time left:</p>
                            <Col className='mb-4'>
                                <ProgressBar
                                    animated
                                    striped
                                    now={getProgress(stake.timestamp, stake.duration)}
                                    label={`${getProgress(stake.timestamp, stake.duration)}%`}
                                    style={{ transition: 'width 2s ease-in-out' }} // Smooth transition effect
                                />
                                <div className="aria-valuenow mt-1" style={{ fontSize: "12px", fontWeight: "700" }}>
                                    {getTimeLeft(stake.timestamp, stake.duration)}
                                </div>
                            </Col>


                            <Col>
                                <Button
                                    variant="success"
                                    className="button-center"
                                    onClick={() => handleClaim(index)}
                                    disabled={!isClaimable(stake.timestamp, stake.duration) || stake.claimed}
                                >
                                    {stake.claimed ? "Claimed" : (<>
                                        Claim
                                    </>)}
                                </Button>
                            </Col>
                        </div>
                    ))}
                </Col>
            </Row>
</div>

    );
};

export default Staking;
