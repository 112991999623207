import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import PROFIT_CENTRE from "../PROFIT_CENTRE"; // Ensure the path to SETTINGS is correct
import SUPPLYS from "../supplys"; // Ensure the path to SETTINGS is correct

const Supplies = () => {
  const [tokenData, setTokenData] = useState({});
  const [nativeData, setNativeData] = useState({});
  const [loading, setLoading] = useState(true);
  const etherScan = 'https://tokens.waveswaps.com/images/chains/etherscan.svg';
  const smartChain = 'https://tokens.waveswaps.com/images/chains/bsc.svg';
  const polyScan = 'https://tokens.waveswaps.com/images/chains/polygonscan.svg';
  const arbiScan = 'https://tokens.waveswaps.com/images/chains/arbiscan.svg';
  const optiScan= 'https://tokens.waveswaps.com/images/chains/optimism.svg';
  const nativeBNB = 'https://tokens.waveswaps.com/images/tokens/bnb.svg';
  const nativeETH = 'https://tokens.waveswaps.com/images/tokens/ethereum.svg';
  const nativePOL = 'https://tokens.waveswaps.com/images/tokens/polygon.svg';
  const feeUSDC = 'https://tokens.waveswaps.com/images/tokens/usdc.svg';
  const feeUSDT = 'https://tokens.waveswaps.com/images/tokens/usdt.svg';
  const feeGBL = 'https://tokens.waveswaps.com/images/tokens/gbl.svg';

  // Utility to get the API URL for different networks
const getApiUrlForNetwork = (
  network,
  tokenAddress,
  walletAddress,
  apiKey
) => {
  let url;
  switch (network) {
    case "Polygon Mainnet":
      url = `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "BNB Smart Chain":
      url = `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "Ethereum Mainnet":
      url = `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "Arbitrum One":
      url = `https://api.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    case "OP Mainnet":
      url = `https://api-optimistic.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      break;
    default:
      console.error("Unsupported network:", network);
      return null;
  }
  console.log(`Generated API URL for ${network}: ${url}`);
  return url;
};

// Updated fetchBalance with enhanced error handling
const fetchBalance = async (asset, walletKey) => {
  const { tokenAddress, tokenDecimals, apiKey, network } = asset;
  const walletAddress = asset[walletKey];

  if (!tokenAddress || tokenAddress === "0x" || !walletAddress || walletAddress === "0x") {
    return { balanceAmount: 0, formattedBalanceAmount: "0.00" };
  }

  const apiUrl = getApiUrlForNetwork(network, tokenAddress, walletAddress, apiKey);
  if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: "0.00" };

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data.status !== "1" || !data.result) {
      console.error(`Error fetching ${walletKey} amount:`, data.message || "Invalid data");
      return { balanceAmount: 0, formattedBalanceAmount: "0.00" };
    }

    const balanceAmount = data.result / Math.pow(10, tokenDecimals);
    const formattedBalanceAmount = balanceAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return { balanceAmount, formattedBalanceAmount };
  } catch (error) {
    console.error(`Error fetching ${walletKey} amount for ${walletKey}`, error);
    return { balanceAmount: 0, formattedBalanceAmount: "0.00" };
  }
};


const fetchAllData = async () => {
  setLoading(true);
  const results = {};

  // Define an array of months with hard-coded values
  const hardCodedMonths = ["Oct_10", "Sept_09", "Aug_08", "Jul_07"];

  for (const [monthName, tokenSettings] of Object.entries(PROFIT_CENTRE.Centres || {})) {
    results[monthName] = {};

    // BNB Smart Chain
    if (hardCodedMonths.includes(monthName) && tokenSettings.networks.bsc.hardCodedValues) {
      const bscValues = tokenSettings.networks.bsc.hardCodedValues;
      results[monthName]["Smartchain Cross Chain Swap"] = bscValues.crossChainSwapBalance;
      results[monthName]["Smartchain V1GBLbot"] = bscValues.v1GBLbot;
      results[monthName]["Smartchain V3GBLbot"] = bscValues.v3GBLbot;
      results[monthName]["Smartchain DEX Liquidity"] = bscValues.dexWalletBalance;
    } else {
      const bscCrossChainSwap = await fetchBalance(tokenSettings.networks.bsc, "crossChainSwap");
      const bscV1GBLbot = await fetchBalance(tokenSettings.networks.bsc, "v1GBLbot");
      const bscV3GBLbot = await fetchBalance(tokenSettings.networks.bsc, "v3GBLbot");
      const bscDEX = await fetchBalance(tokenSettings.networks.bsc, "dexWallet");

      results[monthName]["Smartchain Cross Chain Swap"] = bscCrossChainSwap.formattedBalanceAmount;
      results[monthName]["Smartchain V1GBLbot"] = bscV1GBLbot.formattedBalanceAmount;
      results[monthName]["Smartchain V3GBLbot"] = bscV3GBLbot.formattedBalanceAmount;
      results[monthName]["Smartchain DEX Liquidity"] = bscDEX.formattedBalanceAmount;
    }

    // Polygon
    if (hardCodedMonths.includes(monthName) && tokenSettings.networks.polygon.hardCodedValues) {
      const polygonValues = tokenSettings.networks.polygon.hardCodedValues;
      results[monthName]["Polygon Cross Chain Swap"] = polygonValues.crossChainSwapBalance;
      results[monthName]["Polygon V1GBLbot"] = polygonValues.v1GBLbot;
      results[monthName]["Polygon V3GBLbot"] = polygonValues.v3GBLbot;
      results[monthName]["Polygon Stake Gbl Wave"] = polygonValues.stakeGblWave;
      results[monthName]["Polygon Liquidity Nodes"] = polygonValues.liquidityNodes;
      results[monthName]["Polygon DEX Liquidity"] = polygonValues.dexWalletBalance;
      results[monthName]["Polygon Price Prediction Game"] = polygonValues.pricePredictionGame;
    } else {
      const polygonCrossChainSwap = await fetchBalance(tokenSettings.networks.polygon, "crossChainSwap");
      const polygonV1GBLbot = await fetchBalance(tokenSettings.networks.polygon, "v1GBLbot");
      const polygonV3GBLbot = await fetchBalance(tokenSettings.networks.polygon, "v3GBLbot");
      const polygonStakeGblWave = await fetchBalance(tokenSettings.networks.polygon, "stakeGblWave");
      const polygonLiquidityNodes = await fetchBalance(tokenSettings.networks.polygon, "liquidityNodes");
      const polygonDEX = await fetchBalance(tokenSettings.networks.polygon, "dexWallet");
      const polygonPricePredictionGame = await fetchBalance(tokenSettings.networks.polygon, "pricePredictionGame");

      results[monthName]["Polygon Cross Chain Swap"] = polygonCrossChainSwap.formattedBalanceAmount;
      results[monthName]["Polygon V1GBLbot"] = polygonV1GBLbot.formattedBalanceAmount;
      results[monthName]["Polygon V3GBLbot"] = polygonV3GBLbot.formattedBalanceAmount;
      results[monthName]["Polygon Stake Gbl Wave"] = polygonStakeGblWave.formattedBalanceAmount;
      results[monthName]["Polygon Liquidity Nodes"] = polygonLiquidityNodes.formattedBalanceAmount;
      results[monthName]["Polygon DEX Liquidity"] = polygonDEX.formattedBalanceAmount;
      results[monthName]["Polygon Price Prediction Game"] = polygonPricePredictionGame.formattedBalanceAmount;
    }

    // Arbitrum
    if (hardCodedMonths.includes(monthName) && tokenSettings.networks.arbitrum.hardCodedValues) {
      const arbitrumValues = tokenSettings.networks.arbitrum.hardCodedValues;
      results[monthName]["Arbitrum Cross Chain Swap"] = arbitrumValues.crossChainSwapBalance;
      results[monthName]["Arbitrum V3GBLbot"] = arbitrumValues.v3GBLbot;
      results[monthName]["Arbitrum DEX Liquidity"] = arbitrumValues.dexWalletBalance;
    } else {
      const arbitrumCrossChainSwap = await fetchBalance(tokenSettings.networks.arbitrum, "crossChainSwap");
      const arbitrumV3GBLbot = await fetchBalance(tokenSettings.networks.arbitrum, "v3GBLbot");
      const arbitrumDEX = await fetchBalance(tokenSettings.networks.arbitrum, "dexWallet");

      results[monthName]["Arbitrum Cross Chain Swap"] = arbitrumCrossChainSwap.formattedBalanceAmount;
      results[monthName]["Arbitrum V3GBLbot"] = arbitrumV3GBLbot.formattedBalanceAmount;
      results[monthName]["Arbitrum DEX Liquidity"] = arbitrumDEX.formattedBalanceAmount;
    }

    // Optimism
    if (hardCodedMonths.includes(monthName) && tokenSettings.networks.optimism.hardCodedValues) {
      const optimismValues = tokenSettings.networks.optimism.hardCodedValues;
      results[monthName]["Optimism Cross Chain Swap"] = optimismValues.crossChainSwapBalance;
      results[monthName]["Optimism V3GBLbot"] = optimismValues.v3GBLbot;
      results[monthName]["Optimism DEX Liquidity"] = optimismValues.dexWalletBalance;
    } else {
      const optimismCrossChainSwap = await fetchBalance(tokenSettings.networks.optimism, "crossChainSwap");
      const optimismV3GBLbot = await fetchBalance(tokenSettings.networks.optimism, "v3GBLbot");
      const optimismDEX = await fetchBalance(tokenSettings.networks.optimism, "dexWallet");

      results[monthName]["Optimism Cross Chain Swap"] = optimismCrossChainSwap.formattedBalanceAmount;
      results[monthName]["Optimism V3GBLbot"] = optimismV3GBLbot.formattedBalanceAmount;
      results[monthName]["Optimism DEX Liquidity"] = optimismDEX.formattedBalanceAmount;
    }

    // Ethereum
    if (hardCodedMonths.includes(monthName) && tokenSettings.networks.ethereum.hardCodedValues) {
      const ethereumValues = tokenSettings.networks.ethereum.hardCodedValues;
      results[monthName]["Ethereum DEX Liquidity"] = ethereumValues.dexWalletBalance;
      results[monthName]["Ethereum Cross Chain Swap"] = ethereumValues.crossChainSwapBalance;
      results[monthName]["Ethereum Liquidity Pool"] = ethereumValues.liquidityPoolBalance;
      results[monthName]["Ethereum Ecosystem"] = ethereumValues.ecosystemBalance;
    } else {
      const ethereumDEX = await fetchBalance(tokenSettings.networks.ethereum, "dexWallet");
      const ethereumCrossChainSwap = await fetchBalance(tokenSettings.networks.ethereum, "crossChainSwap");

      results[monthName]["Ethereum DEX Liquidity"] = ethereumDEX.formattedBalanceAmount;
      results[monthName]["Ethereum Cross Chain Swap"] = ethereumCrossChainSwap.formattedBalanceAmount;
    }

    console.log(`Results for ${monthName}:`, results[monthName]);
  }

  setTokenData(results);
  setLoading(false);
};

useEffect(() => {
  fetchAllData();
}, []);



  // Utility to get the API URL for fetching native currency balances
  const getApiUrlForNativeNetwork = (network, walletAddress, apiKey) => {
    switch (network) {
      case "Polygon Mainnet":
        return `https://api.polygonscan.com/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "BNB Smart Chain":
        return `https://api.bscscan.com/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "Ethereum Mainnet":
        return `https://api.etherscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "Arbitrum One":
        return `https://api.arbiscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "OP Mainnet":
        return `https://api-optimistic.etherscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error("Unsupported network:", network);
        return null;
    }
  };

  const fetchNativeBalance = async (networkSettings, walletKey) => {
    const { apiKey, network, nativeCurrencySymbol } = networkSettings;
    const walletAddress = networkSettings[walletKey];
  
    if (!walletAddress || walletAddress === "0x") {
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }
  
    const apiUrl = getApiUrlForNativeNetwork(network, walletAddress, apiKey);
    if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: "0" };
  
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      if (data.status !== "1" || !data.result) {
        console.error(`Error fetching native balance for ${walletKey}`, data.message || "Invalid data");
        return { balanceAmount: 0, formattedBalanceAmount: "0" };
      }
  
      const balanceAmount = data.result / Math.pow(10, 18);
      const formattedBalanceAmount = balanceAmount.toFixed(6) + ` ${nativeCurrencySymbol}`; // Display balance with 6 decimals and symbol
  
      return { balanceAmount, formattedBalanceAmount };
    } catch (error) {
      console.error(`Error fetching native balance for ${walletKey}`, error);
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }
  };
  

  const fetchAllNativeData = async () => {
    setLoading(true);
    const results = {};
  
    // Define an array of months with hard-coded values
    const hardCodedMonths = ["October", "September", "August", "July"];
  
    for (const [monthName, tokenSettings] of Object.entries(PROFIT_CENTRE.Centres || {})) {
      results[monthName] = {};
  
      // Ethereum Native Balance
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.ethereum.hardCodedValues) {
        results[monthName]["Ethereum Native Balance"] = tokenSettings.networks.ethereum.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.ethereum.tokenAddress !== "0x") {
        const ethereumNative = await fetchNativeBalance(tokenSettings.networks.ethereum, "nativeFEE");
        results[monthName]["Ethereum Native Balance"] = parseFloat(ethereumNative.formattedBalanceAmount).toFixed(6);
      }
  
      // BNB Smartchain Native Balance
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.bsc.hardCodedValues) {
        results[monthName]["Smartchain Native Balance"] = tokenSettings.networks.bsc.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.bsc.tokenAddress !== "0x") {
        const bscNative = await fetchNativeBalance(tokenSettings.networks.bsc, "nativeFEE");
        results[monthName]["Smartchain Native Balance"] = parseFloat(bscNative.formattedBalanceAmount).toFixed(6);
      }
  
      // Polygon Native Balance
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.polygon.hardCodedValues) {
        results[monthName]["Polygon Native Balance"] = tokenSettings.networks.polygon.hardCodedValues.nativeFEE;
        results[monthName]["Polygon DAO Voting"] = tokenSettings.networks.polygon.hardCodedValues.daoVoting;
      } else if (tokenSettings.networks.polygon.tokenAddress !== "0x") {
        const polygonNative = await fetchNativeBalance(tokenSettings.networks.polygon, "nativeFEE");
        results[monthName]["Polygon Native Balance"] = parseFloat(polygonNative.formattedBalanceAmount).toFixed(6);
  
        const polygonDAOVoting = await fetchNativeBalance(tokenSettings.networks.polygon, "daoVoting");
        results[monthName]["Polygon DAO Voting"] = parseFloat(polygonDAOVoting.formattedBalanceAmount).toFixed(6);
      }
  
      // Arbitrum Native Balance
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.arbitrum.hardCodedValues) {
        results[monthName]["Arbitrum Native Balance"] = tokenSettings.networks.arbitrum.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.arbitrum.tokenAddress !== "0x") {
        const arbitrumNative = await fetchNativeBalance(tokenSettings.networks.arbitrum, "nativeFEE");
        results[monthName]["Arbitrum Native Balance"] = parseFloat(arbitrumNative.formattedBalanceAmount).toFixed(6);
      }
  
      // Optimism Native Balance
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.optimism.hardCodedValues) {
        results[monthName]["Optimism Native Balance"] = tokenSettings.networks.optimism.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.optimism.tokenAddress !== "0x") {
        const optimismNative = await fetchNativeBalance(tokenSettings.networks.optimism, "nativeFEE");
        results[monthName]["Optimism Native Balance"] = parseFloat(optimismNative.formattedBalanceAmount).toFixed(6);
      }
    }
  
    setNativeData(results);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchAllNativeData();
  }, []);
  
  

  // Helper function to render network and token logos
  const renderNetworkTokenLogos = (network, monthName) => {
    const tokenSettings = PROFIT_CENTRE.Centres[monthName]?.networks[network];

    if (!tokenSettings || tokenSettings.tokenAddress === "0x") {
      return null; // Handle cases where the token is not deployed on this network
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href={tokenSettings.networkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.networkLogo}
            alt="Network Logo"
            style={{ width: "18px", height: "auto", marginRight: "20px" }}
          />
        </a>
        <a
          href={tokenSettings.tokenUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.tokenLogo}
            alt="Token Logo"
            style={{ width: "18px", height: "auto" }}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="supplies-container mt-3 ml-1 mctable">
      <h2 className="pageTitle supplies-header">Profit Centres</h2>
      <p className="hero-p sub-header">Empowering DeFi with Transparent Shareholding and Monthly Revenue Distribution.</p> 
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <button
          onClick={fetchAllData}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "10px",
          }}
          className="refresh-button hero-button "
          disabled={loading}
        >
          {loading ? "Loading..." : "Refresh Data"}
        </button>
      </div>
      <div className="table-responsive mb-3">
        <Table
          className="w-100 table-bordered-custom"
          style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
        >
          <thead>
            <tr style={{ borderBottom: "1px solid #dee2e6" }}>
              <th
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "14px",
                  width: "180px",
                }}
              >
                Profit Centres
              </th>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <th
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "14px",
                    width: "130px",
                  }}
                  key={idx}
                >
                  {PROFIT_CENTRE.Centres[monthName].monthName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* BNB Smart Chain row with logos */}
            <tr>
            <td
              style={{
                textAlign: "left",
                fontWeight: "bold",
                verticalAlign: "middle",
                fontFamily: "Ubuntu, sans-serif",
                fontSize: "14px",
                color: "#555",
                paddingBottom: "3px",
                paddingLeft: "10px"
              }}
            >
              <img
                src={smartChain}
                alt="SmartChain"
                style={{ width: "18px", height: "auto", marginRight: "10px", paddingBottom: "2px"}}
              />
              BNB Smart Chain
            </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                </td>
              ))}
            </tr>

            {/* Smartchain Native Currency FEE with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x07dDF9A4230A0324317e062F9ccD666E26887Db2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in BNB
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading 
                  ? "Loading..." 
                  : nativeData[monthName]?.["Smartchain Native Balance"] 
                  || "N/A"}
                  <img
                  src={nativeBNB} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Smartchain Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0xC7dF6341CF1708B5CFAe6ea37b2CEc95a4E16DF2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cross-Chain Swap Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Smartchain Cross Chain Swap"]
                    ? tokenData[monthName]["Smartchain Cross Chain Swap"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Smartchain V1 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x5578fDCb9212B3B829dd3986C72e745FDACE3BBc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V1 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Smartchain V1 GBL Bot"]
                    ? tokenData[monthName]["Smartchain V1 GBL Bot"]
                    : "N/A"}
                  <img
                  src={feeGBL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Smartchain V3 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x1f796ba09eeF5e03c1c114Ba79170C70362cd148"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V3 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Smartchain V3 GBL Bot"]
                    ? tokenData[monthName]["Smartchain V3 GBL Bot"]
                    : "N/A"}
                    <img
                    src={feeUSDT} 
                    alt="" 
                    lassName="" 
                    style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                  />
                </td>
              ))}
            </tr>

            {/* Smartchain DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x86D3dA36A85E08741E3D33cdB886E64242B55d3a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Smartchain DEX Liquidity"]
                    ? tokenData[monthName]["Smartchain DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDT} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Polygon Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",

                  paddingLeft: "10px"
                }}
              >
              <img
                src={polyScan}
                alt="PolygonScan"
                style={{ width: "18px", height: "auto", marginRight: "10px", paddingBottom: "2px"}}
              />
                Polygon Mainnet
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                </td>
              ))}
            </tr>

            {/* Polygon Native Currency FEE with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in POL
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                {loading 
                ? "Loading..." 
                : nativeData[monthName]?.["Polygon Native Balance"] 
                || "N/A"}
                  <img
                  src={nativePOL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x233Ec5995080CFc861B4a71EEe7766b555bDC22e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cross-Chain Swap Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                  ? "Loading..."
                  : tokenData[monthName] && tokenData[monthName]["Polygon Cross Chain Swap"]
                  ? tokenData[monthName]["Polygon Cross Chain Swap"]
                  : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon V1 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xe7b8583D3A1166E1Bb0d8BB04E65fAB9FaA3E217"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V1 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon V1 GBL Bot"]
                    ? tokenData[monthName]["Polygon V1 GBL Bot"]
                    : "N/A"}
                  <img
                  src={feeGBL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon V3 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x34dFB571c5CE5d91526589103e5782cCcb177f38"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V3 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon V3 GBL Bot"]
                    ? tokenData[monthName]["Polygon V3 GBL Bot"]
                    : "N/A"}
                    <img
                    src={feeUSDT} 
                    alt="" 
                    lassName="" 
                    style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                  />
                </td>
              ))}
            </tr>

            {/* Polygon Stake row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stake (GBL & WAVE) Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon Stake"]
                    ? tokenData[monthName]["Polygon Stake"]
                    : "N/A"}
                  <img
                  src={nativePOL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon Liquidity Nodes row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x5bCa87e3261fA4FEEEE6E83012e48849468e108e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Liquidity Nodes Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon Liquidity Nodes"]
                    ? tokenData[monthName]["Polygon Liquidity Nodes"]
                    : "N/A"}
                  <img
                  src={nativePOL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon Voting row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x2BF314D7e43639cE65c9D8faAa61c77C1557952e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DAO Voting Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                {loading 
                ? "Loading..." 
                : nativeData[monthName]?.["Polygon DAO Voting"] 
                || "N/A"}
                  <img
                  src={nativePOL} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x131439c89353A3379913482A21d1F7e420313Cbd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity USDT Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon DEX Liquidity"]
                    ? tokenData[monthName]["Polygon DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDT} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x131439c89353A3379913482A21d1F7e420313Cbd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity USDC Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon DEX Liquidity"]
                    ? tokenData[monthName]["Polygon DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Polygon Price Prediction Game row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x045725BC613844741fa2050a075b15237E45A23F"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Price Prediction Game
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Polygon Price Prediction Game"]
                    ? tokenData[monthName]["Polygon Price Prediction Game"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Arbitrum Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px"
                }}
              >
              <img
                src={arbiScan}
                alt="Arbitrum One"
                style={{ width: "18px", height: "auto", marginRight: "10px", paddingBottom: "2px"}}
              />
                Arbitrum One
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                </td>
              ))}
            </tr>

            {/* Arbitrum Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                {loading 
                ? "Loading..." 
                : nativeData[monthName]?.["Arbitrum Native Balance"] 
                || "N/A"}
                  <img
                  src={nativeETH} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Arbitrum Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0x131439c89353A3379913482A21d1F7e420313Cbd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cross-Chain Swap Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Arbitrum Cross Chain Swap"]
                    ? tokenData[monthName]["Arbitrum Cross Chain Swap"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Arbitrum V3 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0x5bCa87e3261fA4FEEEE6E83012e48849468e108e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V3 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Arbitrum V3 GBL Bot"]
                    ? tokenData[monthName]["Arbitrum V3 GBL Bot"]
                    : "N/A"}
                    <img
                    src={feeUSDT} 
                    alt="" 
                    lassName="" 
                    style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                  />
                </td>
              ))}
            </tr>

            {/* Arbitrum DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Arbitrum DEX Liquidity"]
                    ? tokenData[monthName]["Arbitrum DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDT} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Optimism Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px"
                }}
              >
              <img
                src={optiScan}
                alt="OP Mainnet"
                style={{ width: "18px", height: "auto", marginRight: "10px", paddingBottom: "2px"}}
              />
                OP Mainnet
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                </td>
              ))}
            </tr>

            {/* Optimism Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                {loading 
                ? "Loading..." 
                : nativeData[monthName]?.["Optimism Native Balance"] 
                || "N/A"}
                  <img
                  src={nativeETH} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Optimism Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x34dFB571c5CE5d91526589103e5782cCcb177f38"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cross-Chain Swap Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Optimism Cross Chain Swap"]
                    ? tokenData[monthName]["Optimism Cross Chain Swap"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>


            {/* Optimism Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0xe7b8583D3A1166E1Bb0d8BB04E65fAB9FaA3E217"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  V3 GBL Bot Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Optimism V3 GBL Bot"]
                    ? tokenData[monthName]["Optimism V3 GBL Bot"]
                    : "N/A"}
                    <img
                    src={feeUSDT} 
                    alt="" 
                    lassName="" 
                    style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                  />
                </td>
              ))}
            </tr>

            {/* Optimism DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x233Ec5995080CFc861B4a71EEe7766b555bDC22e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Optimism DEX Liquidity"]
                    ? tokenData[monthName]["Optimism DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDT} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Ethereum row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px"
                }}
              >
               <img
                src={etherScan}
                alt="Etherscan"
                style={{ width: "18px", height: "auto", marginRight: "10px", paddingBottom: "2px"}}
              />
                Ethereum Mainnet
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                </td>
              ))}
            </tr>

            {/* Ethereum Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                {loading 
                ? "Loading..." 
                : nativeData[monthName]?.["Ethereum Native Balance"] 
                || "N/A"}
                  <img
                  src={nativeETH} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "17px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Ethereum Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cross-Chain Swap Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Ethereum Cross Chain Swap"]
                    ? tokenData[monthName]["Ethereum Cross Chain Swap"]
                    : "N/A"}
                  <img
                  src={feeUSDC} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>

            {/* Ethereum DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Ethereum DEX Liquidity"]
                    ? tokenData[monthName]["Ethereum DEX Liquidity"]
                    : "N/A"}
                  <img
                  src={feeUSDT} 
                  alt="" 
                  ClassName="" 
                  style={{ width: "16px", height: "auto",  marginLeft: "10px", paddingBottom: "2px" }}
                />
                </td>
              ))}
            </tr>


            {/* Empty row without left and right borders */}
       {/*      <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>*/}

            {/* Affiliates row with logos */}
           {/*  <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                Affiliates
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {renderNetworkTokenLogos("ethereum", monthName)}
                </td>
              ))}
            </tr>*/}

            {/* Affiliates row with hyperlink */}
            {/* <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Affiliate 1
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Ethereum Cross Chain Swap"]
                    ? tokenData[monthName]["Ethereum Cross Chain Swap"]
                    : "N/A"}
                </td>
              ))}
            </tr>*/}

            {/* Affiliates row with hyperlink */}
           {/*  <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Affilaite 2
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
                <td
                  key={idx}
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    fontFamily: "Rubik, sans-serif",
                    fontStyle: "italic",
                    fontSize: "13px",
                    color: "#555",
                    paddingRight: "10px",
                  }}
                >
                  {loading
                    ? "Loading..."
                    : tokenData[monthName] &&
                      tokenData[monthName]["Ethereum Liquidity Pool"]
                    ? tokenData[monthName]["Ethereum Liquidity Pool"]
                    : "N/A"}
                </td>
              ))}
            </tr>*/}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Supplies;
