import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import SETTINGS from '../../SETTINGS';
import StandardProposalForm from '../dao/StandardProposalForm';
import StandardProposalList from '../dao/StandardProposalList';


const StandardVoting = ({ provider, selectedAccount, isConnected }) => {
    const [activeTab, setActiveTab] = useState('list');



    useEffect(() => {
        if (!provider) return;

    }, [provider]);

    return (
        <div className='mt-5 page-container'>
        <h3 className='pageTitle'>Standard Voting</h3>
        <p className="hero-p sub-header">
          One vote, one voice – submit proposals and cast your choice with equal voting power across all decisions.
        </p>
        
        <Tabs
          id="standard-voting-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="form" title="Submit Proposal">
            <div className="full-width-tab-submitProp">
              <StandardProposalForm
                provider={provider}
                selectedAccount={selectedAccount}
                isConnected={isConnected}
                setActiveTab={setActiveTab}
              />
            </div>
          </Tab>
          
          <Tab eventKey="list" title="View Proposals">
            <div className="full-width-tab-contentProp">
              <StandardProposalList
                provider={provider}
                selectedAccount={selectedAccount}
                isConnected={isConnected}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
};

export default StandardVoting;