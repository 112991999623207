// src/components/admin/Sidebar.js

import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <Nav className="flex-column p-3">
      <Nav.Item>
        <Link to="/admin/" className="nav-link">
          Home
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/admin/staking-wave" className="nav-link">
          Staking WAVE
        </Link>
      </Nav.Item>
      {/* Add more links as needed */}
    </Nav>
  );
};

export default Sidebar;
