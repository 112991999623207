import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

function SuccessModal({ show, onHide }) {
    const { width, height } = useWindowSize(); // To cover the full window size with confetti
    const navigate = useNavigate();


    const openPortfolio = () => {
        navigate('/list');
      }
  return (
    <>{show && <Confetti width={width} height={height} />}
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header >
      
      </Modal.Header>
      <Modal.Body className="text-center">
        <BiCheckCircle size={100} color="green" />
        <h4 className="mt-3">Purchase Successful!</h4>
        <p>Your purchase has been completed successfully.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" className='buton' onClick={openPortfolio}>
          View Portfolio
        </Button>
        <Button variant="success" className='buton' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default SuccessModal;
