import React, { useEffect, useState } from 'react';
import { BiCopy } from "react-icons/bi";
import Table from 'react-bootstrap/Table';
import MANAGEMENT from '../MANAGEMENT';  // Ensure the path to MANAGEMENT is correct
import '../styles/Management.css';

const Shareholders = () => {
  const [assetData, setAssetData] = useState({});  // State to store data for assets
  const [loading, setLoading] = useState(true);    // Loading state
  const [wsharePrice, setwSharePrice] = useState(0); 

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    switch (network) {
      case 'Polygon Mainnet':
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'BNB Smart Chain':
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error('Unsupported network:', network);
        return null;
    }
  };

  const fetchPrice = async (asset, key) => {
    const {tokenAddress1, tokenAddress2, walletAddress, tokenDecimals1, tokenDecimals2, apiKey, network, percentage } = asset;
    const apiUrl1 = getApiUrlForNetwork(network, tokenAddress1, walletAddress, apiKey);
    const apiUrl2 = getApiUrlForNetwork(network, tokenAddress2, walletAddress, apiKey);
  
    if (!apiUrl1 || !apiUrl2) return { price: 'Error', value: 'Error' };
  
    try {
      const [response1, response2] = await Promise.all([fetch(apiUrl1), fetch(apiUrl2)]);
      const data1 = await response1.json();
      const data2 = await response2.json();
  
      const balance1 = data1.result / Math.pow(10, tokenDecimals1);
      const balance2 = data2.result / Math.pow(10, tokenDecimals2);
      const price = (balance2 / balance1).toFixed(2);
      setwSharePrice(price);
      // Calculate 'value' using 'percentage'
      const value = (price * (percentage)).toFixed(2);
  
      return { price, value };
    } catch (error) {
      console.error('Error fetching price data for', key, error);
      return { price: 'Error', value: 'Error' };
    }
  };
  

  const fetchRevampAmount = async (asset, key) => {
    const { tokenAddress1, wallet, tokenDecimals1, apiKey, network } = asset;
    const apiUrlRevamp = getApiUrlForNetwork(network, tokenAddress1, wallet, apiKey);
  
    if (!apiUrlRevamp) return { revampAmount: 0, formattedRevampAmount: '0.00' };
  
    try {
      const response = await fetch(apiUrlRevamp);
      const data = await response.json();
  
      if (data.status !== '1' || !data.result) {
        console.error('Error fetching revamp amount:', data.message || 'Invalid data');
        return { revampAmount: 0, formattedRevampAmount: '0.00' };
      }
  
      const revampAmount = data.result / Math.pow(10, tokenDecimals1);
      const formattedRevampAmount = revampAmount.toFixed(0).toLocaleString();
  
      return { revampAmount, formattedRevampAmount };
    } catch (error) {
      console.error('Error fetching revamp amount for', key, error);
      return { revampAmount: 0, formattedRevampAmount: '0.00' };
    }
  };
  

  const calculateCirculatingSupply = (totalSupply, revampAmount) => {
    const circulatingSupply = Math.floor(totalSupply - revampAmount);
    return circulatingSupply.toLocaleString();
  };



  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const key of Object.keys(MANAGEMENT.SHAREHOLDERS)) {
      const asset = MANAGEMENT.SHAREHOLDERS[key];
      const priceData = await fetchPrice(asset, key);
      const revampData = await fetchRevampAmount(asset, key);

      const circulatingSupply = calculateCirculatingSupply(asset.totalSupply, revampData.revampAmount);
      results[key] = { ...priceData, ...revampData, circulatingSupply };

      await delay(1000);  // Delay between API calls to avoid rate limits
    }

    setAssetData(results);
    setLoading(false);
  };

  const handleManualRefresh = () => {
    fetchAllData();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId, networkName, rpcUrl, currencySymbol, blockExplorerUrl) => {
    try {
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        return;
      }
          // Ensure chainId is defined and is a number
    if (typeof chainId !== 'number' || isNaN(chainId)) {
      console.error('Invalid chainId:', chainId);
      alert('Error: Invalid network configuration. Please check your settings.');
      return;
    }

      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x' + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x' + chainId.toString(16),
                  chainName: networkName,
                  rpcUrls: [rpcUrl],
                  nativeCurrency: {
                    name: currencySymbol,  
                    symbol: currencySymbol,  
                    decimals: 18,
                  },
                  blockExplorerUrls: [blockExplorerUrl],
                }],
              });
            } catch (addError) {
              console.error('Error adding network:', addError);
              return;
            }
          } else {
            console.error('Error switching network:', switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(`${tokenSymbol} successfully added to MetaMask on ${networkName}`);
      } else {
        console.log('Token addition to MetaMask failed.');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask:', error);
    }
  };

// Function to initiate adding a token and its network to MetaMask
const handleAddToken = (asset) => {
  const { 
    tokenAddress1, symbol, tokenDecimals1, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl 
  } = asset;

  // Check if chainId and other properties are defined before calling addTokenFunction
  if (!chainId || !tokenAddress1 || !symbol || !tokenDecimals1 || !assetLogo || !network || !rpcUrl || !currencySymbol || !blockExplorerUrl) {
    console.error('Missing required asset properties in:', asset);
    alert('Error: Missing asset configuration. Please check your settings.');
    return;
  }

  addTokenFunction(tokenAddress1, symbol, tokenDecimals1, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl);
};
// Function to add the selected token from MANAGEMENT
const addSelectedTokenToMetaMask = (assetKey) => {
  const asset = MANAGEMENT.SHAREHOLDERS[assetKey];

  if (asset) {
    handleAddToken(asset);
  } else {
    console.error('Asset not found in MANAGEMENT.js');
  }
};

// Truncate function to shorten wallet addresses
function truncate(address) {
  if (!address || address.length < 20) return address; // Handle invalid input
  return `${address.slice(0, 8)}...${address.slice(-8)}`;
}


// Example usage for USDC or WaveShare
addSelectedTokenToMetaMask('USDC');
addSelectedTokenToMetaMask('WaveShare');


  return (
    <div className="container tokenstable">
      <h2 className='pageTitle tokens-header'>Shareholders</h2>
      <p className="hero-p sub-header subheader">By holding WSHARE tokens, you gain a voice in the governance of WaveSwaps. This includes the ability to vote on key decisions, propose changes, and help steer the platform towards its long-term goals. The governance model ensures that all shareholders have a stake in the success of WaveSwaps, aligning the interests of the community with the platform's growth.</p> 

      {/* Button for manual refresh and loading state */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button 
          onClick={handleManualRefresh} 
          style={{ padding: '10px 20px', fontSize: '14px', cursor: 'pointer', marginRight: '10px' }}
          className="refresh-button hero-button "
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Refresh Data'}
        </button>
      </div>

      <div className="table-responsive">
        <Table className="w-100 no-border-bottom" style={{ borderCollapse: 'collapse' }}>
        <thead>

          <tr style={{ borderBottom: '1px solid #dee2e6' }}>
            <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Share</th>
            <th style={{ textAlign: 'left', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>ShareHolder</th>
            <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Wallet</th>
            <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Percentage</th>
            <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>WSHARE</th>
            <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Value (USDC)</th>
            <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Contract</th>
            <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Import</th>
            <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>DEX</th>
            <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Chart</th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(MANAGEMENT.SHAREHOLDERS).map((key) => {
            const asset = MANAGEMENT.SHAREHOLDERS[key];
            const assetDataItem = assetData && assetData[key];

            return (
              <tr key={key} style={{ borderBottom: 'none' }}>
                {/* Logo and Chain Explorer Link */}
                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  <a href={asset.chainExplorer} target="_blank" rel="noopener noreferrer">
                    <img src={asset.assetLogo} alt={asset.name} width="23" height="auto" />
                  </a>
                </td>

                {/* Shareholder Name */}
                <td style={{ textAlign: 'left', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  <span className="asset-name">{asset.shareholder}</span>&nbsp;
                </td>

                {/* Wallet Address with Truncate */}
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  <span className="asset-name">{truncate(asset.wallet)}</span>&nbsp;
                </td>

                {/* Percentage */}
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  {asset.percentage.toLocaleString()}.00%
                </td>
                
                {/* Detailed Percentage */}
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  {asset.percentage.toLocaleString()}.0000
                </td>

                {/* Value or Loading State */}
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  {loading ? 'Loading...' : assetDataItem?.value ? parseFloat(assetDataItem.value).toLocaleString() : 'Fetching...'}
                </td>

                {/* Copy Button */}
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                  <button onClick={() => copyToClipboard(asset.tokenAddress1)} className="copy-button">
                    <BiCopy className="icon me-1" />
                  </button>
                </td>

                {/* MetaMask Button */}
                <td style={{ textAlign: 'center' }}>
                  <button onClick={() => handleAddToken(asset)} className="add-token-button">
                    <img src="https://tokens.waveswaps.com/images/metamask.svg" alt="MetaMask" />
                  </button>
                </td>

                {/* Uniswap Link */}
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <a href="https://app.uniswap.org/swap" target="_blank" rel="noopener noreferrer">
                    <img src="https://tokens.waveswaps.com/images/dex/uni.svg" alt="Uniswap" width="20" height="auto" />
                  </a>
                </td>

                {/* Chart Link */}
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <a href={asset.chart} target="_blank" rel="noopener noreferrer">
                    <img src="https://tokens.waveswaps.com/images/cmc.svg" alt="Chart" width="20" height="auto" />
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>

        </Table>
        <p style={{ textAlign: 'left', verticalAlign: 'middle', fontWeight: 'normal', fontFamily: 'Arial, sans-serif', fontSize: '12px', borderTop: '1px solid #dee2e6' }}>
        * ShareHolders with 1% and more of WSHARE(s) with actual price of {wsharePrice} USDC/WSHARE.
      </p>
      </div>
    </div>
  );
};

export default Shareholders;