import React, { useEffect, useState } from 'react';
import governanceImg from '../governance.png';
import { BiBarChart, BiCoinStack, BiWallet, BiCustomize } from 'react-icons/bi';
import logoToken from '../logoToken.svg';
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from '../abis/QtechStakingABI.json';
import NodesABI from '../abis/NodesABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import LevelsLegend from './LevelsLegend';
import CoinSpin from '../components/CoinSpin';
import Global from '../components/Global';
import BtcSpin from '../components/BtcSpin';
import RefferalComponent from '../components/RefferalComponent';
import LevelComponent from '../components/LevelComponent';
import NodeTable from '../components/PowerCores';
import { useNavigate } from 'react-router-dom';
import StripeLoaderImg from '../stripe_loader.svg';
import { BiInfoCircle, BiBox, BiCoin, BiCalendar } from 'react-icons/bi';
import GlobalToken from '../gbl-logo.svg';



function Dashboard({ provider, address, isConnected, networkId, switchNetwork }) {
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [tokenGblBalance, setTokenGblBalance] = useState('0.00');
  const [airdropPower, setAirdropPower] = useState('0.00');
  const [totalNodesAmount, setTotalNodesAmount] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();

  const levelsToShow = ["BTC", "ETH", "XRP", "SOL"]; // Specify the levels you want to include


  const openAffiliate = () => {
    navigate('/affiliate');
}

  const openCraft = () => {
    navigate('/craft');
  }
  const openTokens = () => {
    navigate('/tokens');
}
  const openBuyNode = () => {
      navigate('/store');
  }
  const images = [
    'https://wave.waveswaps.com/wave/wavenode.png',
    'https://wave.waveswaps.com/wave/chronos.png',
    'https://wave.waveswaps.com/wave/eclipse.png',
    'https://wave.waveswaps.com/wave/globalis.png',
    'https://wave.waveswaps.com/wave/quantum.png',
  ];
  const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);
  
      return () => clearInterval(interval);
    }, [images.length]);
  
    return (
      <div className="image-slider rounded">
        {images.map((imgSrc, index) => (
          <img
            key={index}
            src={imgSrc}
            alt={`Slider image ${index + 1}`}
            className={`slider-image ${index === currentIndex ? 'show' : ''}`}
          />
        ))}
      </div>
    );
  };
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };
  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = SETTINGS.NODE_INFO["80"].logoToken; // Ensure that this is the correct URL pointing to your token's image
  
    try {
      // Check if MetaMask is installed
      if (window.ethereum) {
        // Request MetaMask to add the token
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,     // The address of the token
              symbol: tokenSymbol,       // A ticker symbol or shorthand, up to 5 characters
              decimals: tokenDecimals,   // The number of decimals the token uses
              image: tokenImage,         // A string URL of the token logo
            },
          },
        });
  
        if (wasAdded) {
          console.log('Token added to MetaMask successfully!');
        } else {
          console.log('Token addition rejected.');
        }
      } else {
        console.error('MetaMask is not installed. Please install MetaMask to use this feature.');
      }
    } catch (error) {
      console.error('An error occurred while adding the token:', error);
    }
  };

  const countUserNFTs = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const nftsForUser = await contract.getAllNFTsForUser(address);
      const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const nfts = JSON.parse(serialized);
      const nftDataPromises = nfts.map(async (nft) => {
        const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));
        return {
          rawAirdropPower: parseFloat(SETTINGS.NODE_INFO[tokenType.toString()].dao_power),
        };
      });
      const nftData = await Promise.all(nftDataPromises);
      let totalAirdropPow = 0;
      nftData.forEach((temp) => {
        totalAirdropPow += temp.rawAirdropPower;
      });
      setTotalNodesAmount(nftsForUser.length);
      
    }
    catch (e) {
      console.log("count user nfts error");
      console.log(e);
    }
  }

  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {

      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(address);
      setTokenBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      const TokenGblContract = new Contract(SETTINGS.globalTokenAddress, ERC20_ABI, signer);
      const TokenGblBalance = await TokenGblContract.balanceOf(address);
      setTokenGblBalance(parseFloat(formatUnits(TokenGblBalance, 18)).toFixed(2));

      const daoTokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
        const daoBalance = await daoTokenContract.balanceOf(address);
        setAirdropPower(parseFloat(daoBalance.toString()).toFixed(0));
      await getUserLevel();
      await countUserNFTs();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openStakeTokenGbl = () => {
    navigate('/staking-gbl');
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openNodesList = () => {
    navigate('/list');
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }

  useEffect(() => {
    if (isConnected) {
      getBalance();

    } else {
      setQvrsStakes([]);
      setTokenBalance("0.00");
      setTokenGblBalance("0.00");
      setUserLevel(1);
    }
  }, [isConnected, provider]);

  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
   
};

if (networkId != "137" && !SETTINGS.IS_TEST)  {
    return (
        <div className=" text-center">
            <div className="network-switch-section" style={{marginTop:"150px"}}>
             
                  <p>Switch to Polygon network:</p>
                  <Button className="refresh-button hero-button " variant="primary" onClick={handleSwitchNetwork}>
                    Switch to Polygon Network</Button>
                </div>
        </div>
    );
}


  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <img src={StripeLoaderImg} style={{ marginTop: "300px" }} />
        <br />
        <h6 className='loaderMsg'>{txMessage}</h6>
      </div>
    );
  }

  return (
    <div style={{padding:"40px"}}>

   
      
      

      {/* Welcome */}
      <h2 className="dashboard-header">Dashboard</h2> 
      <p className="hero-p sub-header">Craft your unique collection of LIQUIDITY NODES and harness the power of WaveCoin to unlock liquidity for your assets</p> 

      {/* Dashboard */}
      <Row className="mt-4 mb-md-3 mb-0 ">
        {/* WaveCoin */}
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1 card-chat">
          <Card className='card-fix text-center pt-3 chat-message card-height'>
            <Card.Title className='mt-md-3 mt-2'>
              Staking
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              <Row className='mt-4 mb-3 align-items-center'>
                <Col xs={6} md={6}>
                  <center style={{ height: "96px" }}>
                    <CoinSpin />
                  </center>
                </Col>
                <Col xs={6} md={6}>
                  <center style={{ height: "96px" }}>
                    <Global />
                  </center>
                </Col>
              </Row>


              <Row className='mt-2 mb-1 align-items-center'>
                <Col xs={6}>
                  <Button 
                    variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                    onClick={openStakeToken} 
                    className='buton'
                  >
                    Stake WAVE
                  </Button>
                </Col>
                <Col xs={6}>
                 {!SETTINGS.IS_TEST && ( <Button 
                    variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                    onClick={openStakeTokenGbl} 
                    className='buton'
                    /*style={{ height: "42.5px", fontSize: "15px" }}*/
                  >
                    Stake GBL
                  </Button>)}
                </Col>
              </Row>
              {/* <Row>
                <Col xs={12}>
                  <Button variant="primary" className='buton mb-3 mt-2' onClick={importTokenToMetamask}>
                    Import to MetaMask
                  </Button>
                </Col>
              </Row>*/}
              <hr />
              <Row>
                <Col xs={6}className='fw-normal mb-0 pt-0 small card-balance'>
                  <small>Your balance:</small>
                    <br />
                    <span className='card-balance'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)}<br/>{SETTINGS.tokenSymbol}</span>
                </Col>
            
                <Col xs={6} className='fw-normal mb-0 pt-0 small card-balance'>
                  <small>Your balance:</small>
                    <br />
                  <span className='card-balance'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenGblBalance)}<br/>{SETTINGS.gblSymbol}
                    </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* NODES */}
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1 card-chat">
          <Card className='card-fix text-center pt-3 chat-message'>
            <Card.Title className='mt-md-3 mt-2'>
              Liquidity Nodes
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              {/*<LevelComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />*/}
              <Row className='mt-4 mb-4 align-items-center rounded'>
                <Col xs={6} md={6}>
                  <ImageSlider images={images} />
                </Col>
                  {/* Buttons: Market and Craft */}
                <Col xs={6} md={6} className='mb-1'>
                  <Button 
                    variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                    onClick={openBuyNode} 
                    className='buton mb-4'
                  >
                    Market
                  </Button>
                  <Button 
                    variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                    onClick={openCraft} 
                    className='buton'
                  >
                    Craft
                  </Button>
                </Col>
              </Row> 
              <Button variant="primary" className='buton mb-0' onClick={openNodesList}>
                Portfolio
              </Button>
              <hr />
              <RefferalComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
            </Card.Body>
          </Card>
        </Col>

        {/* Airdrop power */}
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1 card-chat">
          <Card className='card-fix text-center pt-3 chat-message card-height'>
            <Card.Title className='mt-md-3 mt-2'>
              DAO Governance
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              <Row className='mt-4 mb-3 align-items-center'>
                <Col xs={6} md={6}>
                  <center style={{ height: "96px" }}>
                  <img src={governanceImg} className='tokenIconColor floatAvatar mx-auto' style={{ width: "90px", marginBottom: "5px" }} alt={"DAO"} /> 
                    {/* <BtcSpin />*/}
                  </center>
                </Col>
                <Col xs={6} md={6}>
                  <Row className='mb-1'>
                      <Col xs={12}>
                      <Button 
                        variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                        onClick={openAffiliate} 
                        className='buton mb-3'
                      >
                        Affiliates
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Col><Button variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                      onClick={openCraft} 
                      className='buton'>
                        Craft
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
              <Col xs={12} md={12}>                 
                   <p className='mt-1 mb-3 small mt-1'>
                    Each earned <img src={governanceImg} className='tokenIconColor' style={{ width: "22px", marginRight: "5px", marginLeft: "4px", marginTop: "-2px" }} alt={"AP"} />boosts your voting power in the
                  </p>
                 <p className='d-flex align-items-center justify-content-center h5'>DAO Voting</p>

                </Col>
              </Row>
              <hr />
              <h6 className='fw-normal mb-0 pt-0 small card-balance'>
                  <small>Your balance:</small> <img src={governanceImg} className='tokenIconColor' style={{ width: "18px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <span className='card-balance'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(airdropPower)} DAO</span>
                </h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Title */}
      <h2 className='liqnodes-header'>Liquidity Node Collection</h2>
      <p className="hero-p sub-header">Build your portfolio with Liquidity Node Collection, designed to generate daily rewards and enhance token liquidity.</p> 

      {/* Levels Legend */}
        <LevelsLegend currentLevel={userLevel} />

      {/* PoverCores 
      <div>
          <h2 className='pageTitle'>LIQUIDITY BOOSTERS COLLECTION</h2>
          <NodeTable levels={levelsToShow} />
        </div>
      <hr />
      */}
          {/* <hr className='d-md-none d-flex' />*/}
      {/* FAQs */}
      <Row className="mt-0 mb-md-4 mb-0 pb-md-2 pb-0">
          <Col xs={12} md={6} lg={4} xl={4} className="mb-1 card-chat">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start'>
                <h6>**WaveCoin</h6>
                <p className='small'>
                  is the platform’s native currency, used for creating Liquidity Nodes, buying crafting elements, staking, and as a transaction method within the WaveSwaps ecosystem.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} xl={4} className="mb-1 card-chat">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start'>
                <h6>**Liquidity Nodes</h6>
                <p className='small'>are digital assets (NFTs) which are operating as nodes. They can be created and crafted through the WaveSwaps dapp.
                  These assets are essential for advancing to higher levels and unlocking greater possibilities.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} xl={4} className="mb-md-1 mb-0 card-chat">
            <Card className='card-fix text-center pt-3 chat-message' style={{ height: "200px" }}>
              <Card.Body className='pt-md-4 text-start d-flex flex-column'>
                <h6>Check out FAQs</h6>
                <p className='small'>
                  Learn more about how your WaveCoin works.
                </p>
                <Button variant="primary buton mt-auto d-flex align-items-center justify-content-center mb-2" onClick={openFAQs} className='buton'>
                  <BiInfoCircle className='me-1' /> OPEN FAQs
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      {/* <hr />*/}
    </div>
  );
}

export default Dashboard;
