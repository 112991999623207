import React, { useState, useEffect } from 'react';
import { Button, Spinner, Row, Col } from 'react-bootstrap';
import boxImg from '../box.png';
import { ERC20_ABI } from "../abis/erc20";
import BridgeABI from '../abis/BridgeABI.json';
import SETTINGS from '../SETTINGS';
import { useNavigate } from 'react-router-dom';
import { BrowserProvider, Contract, formatUnits, parseUnits, JsonRpcProvider } from 'ethers'

const Bridge = ({ provider, account, isConnected, networkName, networkId, switchNetwork, isFirstSite }) => {
    const [loading, setLoading] = useState(false);
    const [showAmountError, setShowAmountError] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [amountToBridge, setAmountToBridge] = useState('');
    const [sourceToken, setSourceToken] = useState('');
    const [targetToken, setTargetToken] = useState('');
    const [targetNetwork, setTargetNetwork] = useState('');
    const [sourceNetworkNativeBalance, setSourceNetworkNativeBalance] = useState('');
    const [targetNetworkNativeBalance, setTargetNetworkNativeBalance] = useState('');
    const [sourceTokenUsdtValue, setSourceTokenUsdtValue] = useState('');
    const [targetTokenAmount, setTargetTokenAmount] = useState('');
    const [txHash, setTxHash] = useState('');
    const [tokenBalance, setTokenBalance] = useState('');
    const [tokensWithBalances, setTokensWithBalances] = useState({});
    const [bridgeTokensWithBalances, setBridgeTokensWithBalances] = useState({});
    const [step, setStep] = useState(0);
    const [isConfirmed, setIsConfirmed] = useState(false);


    const navigate = useNavigate();

    const openLink = (_link) => {
      navigate(_link);
    }
    

    const bridgeFees = {
      10: "110000000000000",
      137: "850000000000000000",
      56: "470000000000000",
      42161: "110000000000000"
    };
    const nativeSymbols = {
      10: "ETH",
      137: "POL",
      56: "BNB",
      42161: "ETH"
    };

    const getBalance = async () => {
      try {
        const ethersProvider = new BrowserProvider(provider);
        const balanceWei = await ethersProvider.getBalance(account);
        const balanceEth = formatUnits(balanceWei, "ether");
        return parseFloat(balanceEth).toFixed(6);
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }
    };


    const setTargetNetworkFunction = async (_networkId) => {
      setTargetNetwork(_networkId);
    
      const bridgeContractAddress = SETTINGS.BRIDGE_CONTRACTS[parseInt(_networkId)];
      if (!bridgeContractAddress) {
        alert("Invalid bridge contract address");
        return;
      }
    
      const targetRpcUrl = SETTINGS.RPC_URLS[parseInt(_networkId)];
      if (!targetRpcUrl) {
        alert("Invalid RPC URL for the target network");
        return;
      }
    
      const ethersProvider = new JsonRpcProvider(targetRpcUrl);

      try {
        const nativeBalance = await ethersProvider.getBalance(account);
        const formattedNativeBalance = parseFloat(formatUnits(nativeBalance, 18)).toFixed(6); // Format to 6 decimals
        setTargetNetworkNativeBalance(formattedNativeBalance);
      } catch (error) {
        console.error("Error fetching native balance:", error);
        setTargetNetworkNativeBalance("0.000000"); // Set to 0 if an error occurs
      }
    
      let tokensWithBalancesDict = {};
    
      const balancePromises = Object.keys(SETTINGS.PRICES).map(async (key) => {
        let tokenName; // Define tokenName here so it’s accessible in both try and catch blocks
    
        try {
          const value = SETTINGS.PRICES[key];
          tokenName = value.name; // Assign tokenName here
          const tokenAddress = SETTINGS.TOKEN_ADDRESSES[tokenName][parseInt(_networkId)];
          console.log(tokenAddress);
          console.log(`Fetching balance for ${tokenName} at ${tokenAddress} on network ${_networkId}`);
    
          if (!tokenAddress) {
            console.warn(`Token address for ${tokenName} is not defined on network ${_networkId}`);
            //tokensWithBalancesDict[tokenName] = { name: tokenName, balance: "0.000000" };
            return;
          }
    
          const TokenContract = new Contract(tokenAddress, ERC20_ABI, ethersProvider);
    
          // Fetch balance and decimals concurrently
          const [TokenBalance, TokenDecimals] = await Promise.all([
            TokenContract.balanceOf(bridgeContractAddress),
            TokenContract.decimals()
          ]);
    
          const formattedBalance = parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
          tokensWithBalancesDict[tokenName] = { name: tokenName, balance: formattedBalance };
        } catch (innerError) {
          console.error(`Failed to fetch balance or decimals for ${tokenName || key} on network ${_networkId}`, innerError);
          
        }
      });
    
      await Promise.all(balancePromises);
    
      setBridgeTokensWithBalances(tokensWithBalancesDict);
    };
    
    
    const setSourceTokenFunction = async (val) => {
        
        setSourceToken(val);
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const sourceTokenAddress = SETTINGS.TOKEN_ADDRESSES[val][parseInt(networkId)];

        const TokenContract = new Contract(sourceTokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6));
    };

    const handleSwitchNetwork = async () => {
        await switchNetwork(parseInt(targetNetwork));
        setStep(4);
    };
    
    const handleClaimTokens = async () => {
      setLoading(true);
      if (!provider || !account) return;
  
      try {
          const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const bridgeAddr = SETTINGS.BRIDGE_CONTRACTS[parseInt(targetNetwork)];
  
          const targetTokenAddress = SETTINGS.TOKEN_ADDRESSES[targetToken][parseInt(targetNetwork)];
          
          // Fetch the token decimals dynamically
          const tokenContract = new Contract(targetTokenAddress, ERC20_ABI, signer);
          const tokenDecimals = await tokenContract.decimals();
  
          // Truncate the targetTokenAmount to the correct precision
          const truncatedAmount = parseFloat(targetTokenAmount).toFixed(parseInt(tokenDecimals));
  
          // Convert the truncated amount to the appropriate wei value
          const amountToClaimWei = parseUnits(truncatedAmount.toString(), tokenDecimals);
  
          setTxMessage("Claiming tokens");
          const contract = new Contract(bridgeAddr, BridgeABI, signer);
          const claimTx = await contract.claimBridge(targetTokenAddress, amountToClaimWei, {
              value: bridgeFees[parseInt(targetNetwork)],
              gasLimit: 700000
          });
  
          const hash = claimTx.hash;
          setTxHash(hash);
          await claimTx.wait();
          setStep(5);
  
      } catch (error) {
          console.error("Claiming failed", error);
      } finally {
          setLoading(false);
      }
  };
  
  
    const handleBridge = async () => {
      setLoading(true);
      if (!provider || !account) return;
      
      try {
          const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const bridgeAddr = SETTINGS.BRIDGE_CONTRACTS[parseInt(networkId)];
  
          const sourceTokenAddress = SETTINGS.TOKEN_ADDRESSES[sourceToken][parseInt(networkId)];
          const targetTokenAddress = SETTINGS.TOKEN_ADDRESSES[targetToken][parseInt(targetNetwork)];
          
          // Fetch the token decimals dynamically
          const tokenContract = new Contract(sourceTokenAddress, ERC20_ABI, signer);
          const tokenDecimals = await tokenContract.decimals();  // Get the token's decimal places
  
          // Calculate the amount in the correct decimal format
          const amountToBridgeWei = parseUnits(amountToBridge.toString(), tokenDecimals);  // Use tokenDecimals here
  
          setTxMessage("Approving " + sourceToken + " transaction...");
          const approveTx = await tokenContract.approve(bridgeAddr, amountToBridgeWei);
          await approveTx.wait();
  
          setTxMessage("Cross-Chain swap in progress");
          const contract = new Contract(bridgeAddr, BridgeABI, signer);
          const bridgeTx = await contract.bridgeSwap(
              sourceTokenAddress, 
              targetTokenAddress, 
              amountToBridgeWei, 
              parseInt(targetNetwork),
              {
                  value: bridgeFees[parseInt(networkId)],  // Ensure this matches `FEE` in the contract
                  gasLimit: 700000  // Increase gas limit to ensure complex operations are covered
              }
          );
          await bridgeTx.wait();
          setStep(3);
  
      } catch (error) {
          console.error("Swap failed", error);
      } finally {
          setLoading(false);
      }
  };
  
    const goToBridge = async () => {
      openLink("bridge");
    };
  
    const startBridge = async () => {
       await loadUserBalances();
        setStep(1);
    };
    const toStepOne = async () => {

        setStep(1);
    };


    const handlePreviewBridge = async () => {

        if(!amountToBridge && parseFloat(amountToBridge) > 0){
          setShowAmountError(true);
          return;
        }
        setShowAmountError(false);
        const sourceTokenUsdtVal = parseFloat(amountToBridge) * parseFloat(SETTINGS.PRICES[sourceToken][parseInt(networkId)]);
        const targetTokenAm = sourceTokenUsdtVal / parseFloat(SETTINGS.PRICES[targetToken][parseInt(targetNetwork)]);
        setSourceTokenUsdtValue(sourceTokenUsdtVal);
        setTargetTokenAmount(targetTokenAm);
        setStep(2);
       
    };


    const handleCheckboxChange = (e) => {
      setIsConfirmed(e.target.checked);
    };
    
    const loadUserBalances = async () => {


      const sourceBal = await getBalance();
      setSourceNetworkNativeBalance(sourceBal);

      let tokensWithBalancesDict = {};
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Collect all balance promises to execute concurrently
      const balancePromises = Object.keys(SETTINGS.PRICES).map(async (key) => {
        try{
          const value = SETTINGS.PRICES[key];
          const name = value.name;
          
          const tokenAddress = SETTINGS.TOKEN_ADDRESSES[name][parseInt(networkId)];

          // If tokenAddress is invalid, set balance to 0 and skip Contract creation
          if (!tokenAddress) {
            
              //tokensWithBalancesDict[name] = { name: name, balance: "0.000000" };
              return;
          }
         
          const TokenContract = new Contract(tokenAddress, ERC20_ABI, signer);

          // Get balance and decimals concurrently for this token
          const [TokenBalance, TokenDecimals] = await Promise.all([
              TokenContract.balanceOf(account),
              TokenContract.decimals()
          ]);

          // Format the balance to 6 decimals
          const formattedBalance = parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
          console.log(formattedBalance);
          // Store the formatted balance in the dictionary
          tokensWithBalancesDict[name] = {
              name: name,
              balance: formattedBalance,
              decimals: parseInt(TokenDecimals)
          };
        }catch (error) {
          console.error("balance load failed", error);
      } 
      });

      // Wait for all balance promises to resolve
      await Promise.all(balancePromises);

      // Set the final balances dictionary
      setTokensWithBalances(tokensWithBalancesDict);
    };
    const getExplorerLink = (network, hash) => {
      const explorers = {
        "137": "https://polygonscan.com/tx/",
        "56": "https://bscscan.com/tx/",
        "42161": "https://arbiscan.io/tx/",
        "10": "https://optimistic.etherscan.io/tx/"
      };
      return explorers[network] ? `${explorers[network]}${hash}` : `#`;
    };

    useEffect(() => {
      if (provider) {
        //loadUserBalances();
        
        if(step < 3){
          setSourceToken("");
          setStep(0);
        }
        
      }
    }, [provider, networkId, networkName]);


    

    if (loading) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }
    return (
        <div className="bridge-container text-center ">
        <h3 className="pageTitle crosschain-header">Cross-Chain Swap</h3>
        <p className="hero-p">Effortlessly swap tokens across multiple networks with real-time tracking and seamless liquidity.</p> 
        <Row className="justify-content-center pt-3">
          <Col sm={8} md={8} lg={6}>
          <h4 className="swap-form-header">Create New Wave Swap</h4>
            <div className="chat-message card-fix text-center">
            <div className="bot-card-header mb-2" style={{width: "100%", backgroundColor: "#7da7f0", borderRadius: "5px", padding: "5px"}}>
              <label className="step-indicator mb-2 mt-2" style={{ color: "#FFFFFF" }}>Step <bstep>{step}</bstep> of <bstep>5</bstep>
              </label>
            </div>
            {step === 0 && (
                <div className="network-switch-section">
                <p>Start Cross-Chain Swap:</p>
                {SETTINGS.IS_TEST ? (
<p className='test-button'>Not available in test enironment.</p>
                ):(<>
                {isFirstSite ? (
                  <Button variant="primary" onClick={goToBridge} className='button-center'>Start
                  </Button>

                ):(
              <Button variant="primary" onClick={startBridge} className='button-center'>Start
              </Button>
                )}
                </>
                  
                )}
                
              </div>
            )}
              {step === 1 && (
                <div className="swap-form">
                  <div className="form-group mb-3">
                    <label className="form-label">Select Source Token:</label>
                    <select 
                      value={sourceToken} 
                      onChange={(e) => setSourceTokenFunction(e.target.value)} 
                      className="form-select waweSwap">
                      <option disabled selected value="">Select Source Token</option>
                      {Object.values(tokensWithBalances).map((value, index) => (
                        <option key={index} value={value.name}>{value.name} - <small>{value.balance} {value.name}</small></option>
                      ))}
                    </select>
                  </div>
      
                  {sourceToken && (
                    <>
                      <div className="form-group mb-3">
                        <label className="form-label">Enter the Amount of Source Tokens:</label>
                        <input 
                          type="number" 
                          value={amountToBridge} 
                          onChange={(e) => setAmountToBridge(e.target.value)} 
                          className="form-select" 

                          placeholder="Enter the Amount of Source Tokens" />
                        {showAmountError && (
                            <small className="warning-text text-danger" style={{textAlign:"left"}}>
                            <strong>Warning:</strong> Please enter amount.
                          </small>
                          )}
                        <small className="form-text">Balance: {tokenBalance} {sourceToken}</small>
                        <small className="form-text">Balance: {sourceNetworkNativeBalance} {nativeSymbols[parseInt(networkId)]}</small>
                      
                       
                      </div>
      
                      <div className="form-group mb-3">
                        <label className="form-label">Select Target Network:</label>
                        <select 
                          value={targetNetwork} 
                          onChange={(e) => setTargetNetworkFunction(e.target.value)} 
                          className="form-select">
                          <option disabled selected value="">Select Target Network</option>
                          {networkId !== "137" && <option value="137">Polygon</option>}
                          {networkId !== "56" && <option value="56">BNB Smart Chain</option>}
                          {networkId !== "42161" && <option value="42161">Arbitrum One</option>}
                          {networkId !== "10" && <option value="10">OP Mainnet</option>}
                        </select>
                      </div>
                    </>
                  )}
      
                  {targetNetwork && (
                    <div className="form-group mb-4">
                      <label className="form-label">Select Target Token:</label>
                      <select 
                        value={targetToken} 
                        onChange={(e) => setTargetToken(e.target.value)} 
                        className="form-select">
                        <option disabled selected value="">Select Target Token</option>
                        {Object.values(bridgeTokensWithBalances).map((value, index) => (
                          <option key={index} value={value.name}>{value.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
      
                  {targetToken && (
                    <Button variant="success" onClick={handlePreviewBridge}>Preview</Button>
                  )}
                </div>
              )}
{step === 2 && (
  <div className="summary-section" style={{textAlign:"left"}}>
    <h4 className="review-header">Review Your Swap Details</h4>
    <p className="summary-intro">Please confirm the details below before proceeding with your swap:</p>
    <ul className="swap-details-list" style={{textAlign:"left"}}>
  <li>
    Source Token:<strong> {parseFloat(amountToBridge).toFixed(6)} {sourceToken}</strong>
  </li>
  <li>
    Target Token:<strong> {parseFloat(targetTokenAmount).toFixed(6)} {targetToken}</strong>
  </li>

</ul>

    <p className="description terms-text">
    <small>
      <i>Important:</i> Make sure you have at least double the network fee in your target network balance to cover the gas and swap fees smoothly
    </small>
    </p>

    {/* Insufficient balance warning */}
    {parseFloat(targetNetworkNativeBalance) < 2 * parseFloat(formatUnits(bridgeFees[targetNetwork], 18)) && (
      <p className="warning-text text-danger">
      <strong>Warning:</strong> Your native balance on the target network is too low. Please ensure you have enough native currency to cover at least twice the required network fee for this transaction.
    </p>
    
    )}

    <p className="terms-text">
     

      <div className="terms-text d-flex align-items-start">
        <input
          type="checkbox"
          id="confirm-terms"
          checked={isConfirmed}
          onChange={handleCheckboxChange}
          style={{marginTop:"7px"}}
        />
        <label htmlFor="confirm-terms" className="ms-2">
        <small>I confirm that I will not refresh or leave the page until the swapping process is complete, acknowledging that doing so may result in the loss of my balance and that this transaction is irreversible hence cannot be claimed.</small>
        </label>
      </div>
<hr />
      <small>
        By clicking "Confirm Swap" you agree to WaveSwaps'{" "}
        <a href="https://docs.waveswaps.com/corporate/terms-and-conditions" target="_blank" className="terms-link">
          Terms and Conditions
        </a>.
      </small>
    </p>
    <div className="text-center">
      <Button
        variant="success"
        className="confirm-button"
        onClick={handleBridge}
        disabled={(parseFloat(targetNetworkNativeBalance) < 2 * parseFloat(formatUnits(bridgeFees[targetNetwork], 18)) || !isConfirmed)}
      >
        Confirm Swap
      </Button><br />
      <Button variant="outline-secondary" className="back-button" onClick={toStepOne}>Go Back</Button>
    </div>
  </div>
)}



{step === 3 && (
  <div className="network-switch-section">
    <h4 className="step-header">Step 3: Switch to Target Network</h4>
    <p>To continue with the transaction, please switch to the<br /> <strong>{targetNetwork == "137" && "Polygon"}
    {targetNetwork == "56" && "BNB Smart Chain"}
    {targetNetwork == "42161" && "Arbitrum One"}
    {targetNetwork == "10" && "OP Mainnet"}</strong> network.</p>
    <center>
      <Button variant="success" onClick={handleSwitchNetwork} className="confirm-button">Switch Network</Button>
      </center>
    <hr />
    <p className="helper-text">Completing this step is essential to receive your {targetToken} tokens on the <strong>{targetNetwork == "137" && "Polygon"}
    {targetNetwork == "56" && "BNB Smart Chain"}
    {targetNetwork == "42161" && "Arbitrum One"}
    {targetNetwork == "10" && "OP Mainnet"}</strong> network.</p>
  </div>
)}

{step === 4 && (
  <div className="claim-section">
    <h4 className="step-header">Step 4: Claim Your Tokens</h4>
    <p>Your tokens are ready to be claimed on the <br />
    <strong> {targetNetwork == "137" && "Polygon"}
    {targetNetwork == "56" && "BNB Smart Chain"}
    {targetNetwork == "42161" && "Arbitrum One"}
    {targetNetwork == "10" && "OP Mainnet"}</strong> network.</p>
    <center>
    <Button variant="success" onClick={handleClaimTokens} className="confirm-button">Claim Tokens</Button>
    </center>
    <hr />
    <p className="helper-text">
     Click 'Claim Tokens' to complete the swap and receive {targetTokenAmount} {targetToken} on network
    <strong>{targetNetwork == "137" && "Polygon"}
    {targetNetwork == "56" && "BNB Smart Chain"}
    {targetNetwork == "42161" && "Arbitrum One"}
    {targetNetwork == "10" && "OP Mainnet"}</strong>.</p>
  </div>
)}

{step === 5 && (
  <div className="success-section" style={{textAlign:"left"}}>
    <h4 className="step-header">Transaction Successful!</h4>
    <p>Congratulations! Your swap is complete. Here are the final details:</p>
    <ul className="transaction-summary" style={{textAlign:"left"}}>
    <li>
  <strong>Swapped Source Token:</strong> {parseFloat(amountToBridge || 0).toFixed(6)} {sourceToken}
</li>
<li>
  <strong>Received Target Token:</strong> {parseFloat(targetTokenAmount || 0).toFixed(6)} {targetToken}
</li>
<li>
  <strong>Target Network:</strong> {targetNetwork == "137" && "Polygon"}
    {targetNetwork == "56" && "BNB Smart Chain"}
    {targetNetwork == "42161" && "Arbitrum One"}
    {targetNetwork == "10" && "OP Mainnet"} ({nativeSymbols[targetNetwork]})
</li>

    </ul>
    <p className="helper-text">Your tokens are now available on the target network. Thank you for using WaveSwaps!
      <hr />
      
      <small>
    <a href={`${getExplorerLink(targetNetwork, txHash)}`} target="_blank" rel="noopener noreferrer">
    View Transaction.
    </a></small>
    </p>
    <Button variant="success" onClick={() => setStep(0)} className="restart-button">Start Again</Button>
  </div>
)}

            </div>
          </Col>
        </Row>
      </div>
      
    );
};

export default Bridge;
