const SUPPLYS= {
    "GlobalToken": {
        "Ethereum Total": "17,900,000",
        "Ethereum Custody": "16,781,250",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "0",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "1,118,750",
        "Smartchain Total": "17,900,000",
        "Smartchain Custody": "8,950,000",
        "Smartchain Recycle Pool": "0",
        "Smartchain Swap": "8,030",
        "Smartchain Circulating": "8,941,970",
        "Polygon Total": "17,900,000",
        "Polygon Custody": "13,425,000",
        "Polygon Recycle Pool": "500",
        "Polygon Swap": "30",
        "Polygon Circulating": "4,474,469",
        "Arbitrum Total": "17,900,000",
        "Arbitrum Custody": "15,662,500",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "50",
        "Arbitrum Circulating": "2,237,450",
        "Optimism Total": "17,900,000",
        "Optimism Custody": "16,781,250",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "30",
        "Optimism Circulating": "1,118,720",
        "Total Circulating": "17,891,359"
    },
    "CryptoCode": {
        "Ethereum Total": "1,000,000",
        "Ethereum Custody": "0",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "0",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "1,000,000",
        "Smartchain Total": "21,000,000",
        "Smartchain Custody": "19,281,644",
        "Smartchain Recycle Pool": "441,843",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "1,276,512",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "5,001",
        "Polygon Swap": "100",
        "Polygon Circulating": "994,798",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "100",
        "Arbitrum Circulating": "999,900",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "100",
        "Optimism Circulating": "999,900",
        "Total Circulating": "5,271,110"
    },
    "MetaCoin": {
        "Ethereum Total": "1,000,000",
        "Ethereum Custody": "0",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "0",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "1,000,000",
        "Smartchain Total": "210,000,000",
        "Smartchain Custody": "28,366,145",
        "Smartchain Recycle Pool": "40,256,631",
        "Smartchain Swap": "49,999",
        "Smartchain Circulating": "141,327,223",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "5,001",
        "Polygon Swap": "100",
        "Polygon Circulating": "994,799",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "100",
        "Arbitrum Circulating": "999,900",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "100",
        "Optimism Circulating": "999,900",
        "Total Circulating": "145,321,822"
    },
    "PiplCoin": {
        "Ethereum Total": "210,000,000",
        "Ethereum Custody": "18,289,750",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "4,024,023",
        "Ethereum Ecosystem": "97,640,358",
        "Ethereum Circulating": "90,045,867",
        "Smartchain Total": "210,000,000",
        "Smartchain Custody": "71,009,634",
        "Smartchain Recycle Pool": "22,081,564",
        "Smartchain Swap": "50,000",
        "Smartchain Circulating": "116,858,800",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "5,000",
        "Polygon Swap": "100",
        "Polygon Circulating": "994,800",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "100",
        "Arbitrum Circulating": "999,900",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "100",
        "Optimism Circulating": "999,900",
        "Total Circulating": "209,899,268"
    },
    "GetonCoin": {
        "Ethereum Total": "1,542,000,000",
        "Ethereum Custody": "1,444,919,779",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "42,907,396",
        "Ethereum Ecosystem": "52,072,011",
        "Ethereum Circulating": "2,100,811",
        "Smartchain Total": "1,542,000,000",
        "Smartchain Custody": "1,510,598,006",
        "Smartchain Recycle Pool": "3,834,166",
        "Smartchain Swap": "100",
        "Smartchain Circulating": "27,567,726",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "50",
        "Polygon Swap": "10",
        "Polygon Circulating": "999,840",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "100",
        "Arbitrum Circulating": "999,900",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "100",
        "Optimism Circulating": "999,900",
        "Total Circulating": "32,668,178"
    },
    "GroshCoin": {
        "Ethereum Total": "210,000,000",
        "Ethereum Custody": "0",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "91,200,428",
        "Ethereum Ecosystem": "118,799,572",
        "Ethereum Circulating": "0",
        "Smartchain Total": "210,000,000",
        "Smartchain Custody": "25,908,568",
        "Smartchain Recycle Pool": "24,475,146",
        "Smartchain Swap": "3,000",
        "Smartchain Circulating": "159,613,284",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "5,000",
        "Polygon Swap": "100",
        "Polygon Circulating": "994,800",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "100",
        "Arbitrum Circulating": "999,900",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "100",
        "Optimism Circulating": "999,900",
        "Total Circulating": "162,607,884"
    },
    "PronCoin": {
        "Ethereum Total": "69,000,000",
        "Ethereum Custody": "17,484,731",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "198,635",
        "Ethereum Ecosystem": "585,673",
        "Ethereum Circulating": "50,730,958",
        "Smartchain Total": "69,000,000",
        "Smartchain Custody": "63,232,340",
        "Smartchain Recycle Pool": "378,354",
        "Smartchain Swap": "100",
        "Smartchain Circulating": "5,389,205",
        "Polygon Total": "1,000,000",
        "Polygon Custody": "100",
        "Polygon Recycle Pool": "5",
        "Polygon Swap": "10",
        "Polygon Circulating": "999,885",
        "Arbitrum Total": "1,000,000",
        "Arbitrum Custody": "0",
        "Arbitrum Recycle Pool": "0",
        "Arbitrum Swap": "10",
        "Arbitrum Circulating": "999,990",
        "Optimism Total": "1,000,000",
        "Optimism Custody": "0",
        "Optimism Recycle Pool": "0",
        "Optimism Swap": "10",
        "Optimism Circulating": "999,990",
        "Total Circulating": "59,120,029"
    },
    "LifeOnScreen": {
        "Ethereum Total": "1,125,000,000",
        "Ethereum Custody": "1,096,977,526",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "0",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "28,022,474",
        "Smartchain Total": "1,000,048",
        "Smartchain Custody": "867,774",
        "Smartchain Recycle Pool": "3,997",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "128,276",
        "Total Circulating": "28,150,750"
    },
    "CarbCoin": {
        "Ethereum Total": "743,100,000",
        "Ethereum Custody": "297,838,088",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "207,165,315",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "238,096,597",
        "Smartchain Total": "743,100,000",
        "Smartchain Custody": "741,712,724",
        "Smartchain Recycle Pool": "3,339",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "1,383,935",
        "Total Circulating": "239,480,532"
    },
    "CryptoBoard": {
        "Ethereum Total": "6,184,089",
        "Ethereum Custody": "4,272,240",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "238,693",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "1,673,156",
        "Smartchain Total": "6,184,089",
        "Smartchain Custody": "6,026,931",
        "Smartchain Recycle Pool": "0",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "157,157",
        "Total Circulating": "1,830,313"
    },
    "AirPodToken": {
        "Ethereum Total": "147,792,670",
        "Ethereum Custody": "48,255,912",
        "Ethereum Recycle Pool": "0",
        "Ethereum Swap": "0",
        "Ethereum Liquidity Pool": "392",
        "Ethereum Ecosystem": "0",
        "Ethereum Circulating": "99,536,366",
        "Smartchain Total": "147,792,670",
        "Smartchain Custody": "146,379,353",
        "Smartchain Recycle Pool": "0",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "1,413,316",
        "Total Circulating": "100,949,682"
    },
    "eGROSH": {
        "Smartchain Total": "210,000,000",
        "Smartchain Custody": "192,892,862",
        "Smartchain Recycle Pool": "0",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "17,107,137",
        "Total Circulating": "17,107,137"
    },
    "GMAAR": {
        "Smartchain Total": "57000000",
        "Smartchain Custody": "35,601,523",
        "Smartchain Recycle Pool": "6,874,656",
        "Smartchain Swap": "0",
        "Smartchain Circulating": "14,523,819",
        "Total Circulating": "14,523,819"
    }
};

export default SUPPLYS;