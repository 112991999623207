import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SiteMap = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => navigate(path);

  return (
    <div className="site-map page-container">
      <Container>
        <h1 className="pageTitle supplies-header">Site Map</h1>
        <p className="hero-p sub-header">
          Navigate our platform with ease using the Site Map, your guide to all features, resources, and essential tools across WaveSwaps.
        </p>
        <Row>
          {/* Platform Sections */}
          <Col md={4}>
            <h2 className="sitemap-header">WaveSwaps</h2>
            <ul className="site-map-list">
              <li onClick={handleNavigation('/roadmap')} className="site-map-link">Roadmap</li>
              <li onClick={handleNavigation('/faqs')} className="site-map-link">FAQ</li>
              <li onClick={handleNavigation('/supplies')} className="site-map-link">Supplies</li>
              <li onClick={handleNavigation('/tokens')} className="site-map-link">Tokens</li>
              <li onClick={handleNavigation('/store')} className="site-map-link">Market</li>
            </ul>
          </Col>

          {/* Features Sections */}
          <Col md={4}>
            <h2 className="sitemap-header">Features</h2>
            <ul className="site-map-list">
              <li onClick={handleNavigation('/liquidity-engines')} className="site-map-link">Liquidity Engines</li>
              <li onClick={handleNavigation('/bridge')} className="site-map-link">Cross-Chain WaveSwap</li>
              <li onClick={handleNavigation('/staking')} className="site-map-link">WaveCoin Staking</li>
              <li onClick={handleNavigation('/staking-gbl')} className="site-map-link">GBL Staking</li>
              <li onClick={handleNavigation('/nodes-info')} className="site-map-link">About Nodes</li>
              <li onClick={handleNavigation('/craft')} className="site-map-link">Crafting</li>
              <li onClick={handleNavigation('/portfolio')} className="site-map-link">Portfolio</li>
              <li onClick={handleNavigation('/power-core-collection')} className="site-map-link">Power Core Collection</li>
            </ul>
          </Col>

          {/* Legal & Resources */}
          <Col md={4}>
            <h2 className="sitemap-header">Legal & Resources</h2>
            <ul className="site-map-list">
              <li onClick={handleNavigation('/docs')} className="site-map-link">Documentation</li>
              <li><a href="https://docs.waveswaps.com/corporate/privacy-policy" className="site-map-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              <li><a href="https://docs.waveswaps.com/corporate/terms-and-conditions" className="site-map-link" target="_blank" rel="noopener noreferrer">Terms of Service</a></li>
              <li onClick={handleNavigation('/affiliate')} className="site-map-link">Affiliates</li>
              <li onClick={handleNavigation('/dao-standard')} className="site-map-link">Standard Voting</li>
              <li onClick={handleNavigation('/dao-weighted')} className="site-map-link">Weighted Voting</li>
              <li><a href="https://docs.waveswaps.com/corporate/fee-schedule" className="site-map-link" target="_blank" rel="noopener noreferrer">FEE Schedule</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SiteMap;
