// src/components/admin/StakingWaveComponent.js

import React, { useState, useEffect } from 'react';
import { Button, Form, ListGroup, Spinner, Alert } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import StakingABI from '../../abis/StakingABI.json';
import SETTINGS from '../../SETTINGS';

const StakingWaveComponent = ({ provider, account, isConnected }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fee, setFee] = useState('');
  const [newFee, setNewFee] = useState('');
  const [stakes, setStakes] = useState([]);
  const [txMessage, setTxMessage] = useState('');

  // Initialize contract
  const getContractInstance = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    return new Contract(SETTINGS.stakeAddress, StakingABI, signer);
  };

  // Load current fee
  const loadFee = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.stakeAddress, StakingABI, signer);
      console.log("Contract instance:", contract);
  
      const currentFee = await contract.FEE.call();
      console.log("Current Fee (raw):", currentFee);
      alert(1);
      alert(currentFee.toString()); // Ensure to convert BigNumber to string
      setFee(formatUnits(currentFee, 'ether'));
    } catch (error) {
      setError("Failed to load fee");
      console.error("Error loading fee:", error);
    } finally {
      setLoading(false);
    }
  };
  

  // Load user's stakes
  const loadUserStakes = async () => {
    try {
      setLoading(true);
      const contract = getContractInstance();
      const userStakes = await contract.getUserStakes(account);
      const formattedStakes = userStakes.map(stake => ({
        amount: formatUnits(stake.amount, 'ether'),
        duration: stake.duration / (24 * 60 * 60), // convert seconds to days
        claimed: stake.claimed,
      }));
      setStakes(formattedStakes);
    } catch (error) {
      setError("Failed to load stakes");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Change the fee (onlyOwner)
  const handleFeeChange = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const contract = getContractInstance();
      const tx = await contract.changeFEE(parseUnits(newFee, 'ether'));
      setTxMessage("Transaction submitted. Waiting for confirmation...");
      await tx.wait();
      setTxMessage("Fee updated successfully!");
      loadFee(); // Reload fee after update
    } catch (error) {
      setError("Failed to change fee");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Initial data loading
  useEffect(() => {
    if (isConnected) {
      loadFee();
      loadUserStakes();
    }
  }, [isConnected, account]);

  return (
    <div>
      <h2>StakingWave Contract Management</h2>

      {/* Display error message if any */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Display transaction message */}
      {txMessage && <Alert variant="info">{txMessage}</Alert>}

      {/* Loading Spinner */}
      {loading && <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}

      {/* Display Current Fee */}
      <h4>Current Fee: {fee} ETH</h4>

      {/* Change Fee Form (only for contract owner) */}
      <Form onSubmit={handleFeeChange}>
        <Form.Group controlId="newFee">
          <Form.Label>Set New Fee (ETH)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter new fee in ETH"
            value={newFee}
            onChange={(e) => setNewFee(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading || !isConnected}>
          Update Fee
        </Button>
      </Form>

      {/* List of Stakes */}
      <h4 className="mt-4">Your Stakes</h4>
      <ListGroup>
        {stakes.length === 0 ? (
          <ListGroup.Item>No stakes found.</ListGroup.Item>
        ) : (
          stakes.map((stake, index) => (
            <ListGroup.Item key={index}>
              Amount: {stake.amount} ETH | Duration: {stake.duration} days | Claimed: {stake.claimed ? "Yes" : "No"}
            </ListGroup.Item>
          ))
        )}
      </ListGroup>
    </div>
  );
};

export default StakingWaveComponent;
