import React, { useState } from 'react';
import { Row, Col, Modal, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logoToken from '../logoToken.svg';
import SETTINGS from '../SETTINGS';

const LevelsLegend = ({ currentLevel }) => {
  const levels = ["1", "2", "3", "4", "5", "6"];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [modalContent, setModalContent] = useState(null);
  const [isMobile] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (level) => {
   
    const content = SETTINGS.NODE_INFO[level]; // Assuming content is in SETTINGS.NODE_INFO
    setModalContent(content);
    setShow(true);
  };
  const openBuyNode = () => {
    navigate('/store');
}
const openCraft = () => {
  navigate('/craft');
}

  return (
      <>
        {/* Levels */}
        <div className="scrollable-row">
          <Row className="flex-nowrap">
            {levels.map((level, index) => (
              <Col
                key={index}
                xs={9}  // Full width on mobile
                sm={7}   // Half width on tablets
                md={5}   // One-third width on medium screens
                lg={3}   // One-third width on large screens
                style={{
                  display: parseInt(currentLevel) <= parseInt(level) ? "" : "",
                }}
              >
                <div
                  className="level-card mb-3"
                  onClick={() => handleShow(level)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={SETTINGS.NODE_INFO[level].imgUrl}
                    alt=""
                    className="levelImg rounded"
                    style={{
                      filter:
                        parseInt(currentLevel) >= parseInt(level)
                          ? ""
                          : "grayscale(100%)",
                      opacity:
                        parseInt(currentLevel) >= parseInt(level) ? 1 : "0.52",
                    }}
                  />
                  <h6 className='levelTitle' style={{
                    marginTop: "8px",
                    color: parseInt(currentLevel) >= parseInt(level) ? "#FFFFFF" : "#393939",
                    backgroundColor: parseInt(currentLevel) >= parseInt(level) ? "" : "#eeeeee",
                    textAlign: "center"// Center align the title
                  }}>
                    <small>Level</small> {level}
                  </h6>
                </div>
              </Col>
            ))}
          </Row>
        </div>




        {/* Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className='pt-0'>
            <Modal.Title>
              <h3 className='mb-0'>
                <Image src={modalContent?.imgUrl} rounded className="me-2" style={{ width: '80px', height: '80px' }} /> {modalContent?.level}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <h5 className='mb-3'>Rewards:</h5>
            <p className='small'>
              <b pill bg="info" className='me-1 '>{modalContent?.refferalPercent} % referral </b> in 
              <b> 
                <img src={logoToken} className='tokenIconColor' style={{ width: "28px", marginRight: "4px", marginLeft: "4px", marginBottom: "3px" }} alt={SETTINGS.tokenSymbol} />
                {SETTINGS.tokenSymbol}
              </b>
            </p>

            {parseInt(modalContent?.refferalPercent) > 0 && (
              <p className='small'>
                <b>1x</b>
                <Image src={SETTINGS.NODE_INFO["80"].imgUrl} rounded className="me-2 mb-1 mr-2" style={{ width: '40px', height: '40px', marginLeft: "7px" }} title={SETTINGS.NODE_INFO["80"].hoverText} />
                for you {modalContent?.refferalPercent >= 5 && (
                  <>
                    <b>1x</b> 
                    <Image src={SETTINGS.NODE_INFO["80"].imgUrl} rounded className="me-1 mb-1 ml-1" style={{ width: '40px', height: '40px', marginLeft: "7px" }} title={SETTINGS.NODE_INFO["80"].hoverText} /> 
                    & for your referee 
                  </>
                )} 
                when your referral code is used to create <b>{modalContent?.level}</b>.
              </p>
            )}
            <hr />
            {parseInt(modalContent?.refferalPercent) > 0 ? (
              <div>
                <Row className='mb-3 mt-3 pt-2'>
                  {/* First column for Craft */}
                  <Col xs={8}>
                    <p className='small'>
                      Craft with
                      {/* <Image 
                        src={modalContent?.imgUrl} 
                        rounded 
                        className="me-2 mb-2" 
                        style={{ width: '40px', height: '40px', marginLeft: "6px" }} 
                        title={modalContent?.hoverText}
                      />*/}
                      {modalContent?.craftElements.map((imgMod, index) => {
                        const craftElement = Object.values(SETTINGS.NODE_INFO).find(node => node.imgUrl === imgMod);
                        return (
                          <Image 
                            key={index} 
                            src={imgMod} 
                            rounded 
                            className="ms-2 mb-2" 
                            style={{ width: '40px', height: '40px' }} 
                            title={craftElement?.hoverText}
                          />
                        );
                      })}
                    </p>
                  </Col>

                  {/* Second column for the Craft button */}
                  <Col xs={4} className="d-flex align-items-center justify-content-end">
                    <Button 
                      variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                      onClick={openCraft} 
                      className='buton mb-4'
                    >
                      Craft
                    </Button>
                  </Col>
                </Row>

                <Row className='mb-3 mt-3 pt-2'>
                  {/* First column for Buy */}
                  <Col xs={8}>
                    <p>
                      Buy with
                      {/* <Image 
                        src={modalContent?.imgUrl} rounded className="me-2" style={{ width: '40px', height: '40px', marginLeft: "6px" }} title={modalContent?.hoverText} /> with*/}
                      <b className='ms-2'> <img src={logoToken} 
                          className='tokenIconColor' style={{ width: "28px", marginRight: "4px", marginLeft: "0px", marginBottom: "3px" }} alt={SETTINGS.tokenSymbol} />{modalContent?.price} {SETTINGS.tokenSymbol}
                      </b>
                    </p>
                  </Col>

                  {/* Second column for the Buy button */}
                  <Col xs={4} className="d-flex align-items-center justify-content-end">
                    <Button 
                      variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                      onClick={openBuyNode} 
                      className='buton mb-4'
                    >
                      Buy
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div></div>
            )}

          </Modal.Body>
          <Modal.Footer className='pb-1 pt-3'>
            <Button variant="buton buton-secondary" className="buton" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default LevelsLegend;
