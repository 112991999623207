import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import SETTINGS from "../SETTINGS";
import { BiXCircle } from 'react-icons/bi';


const ToastNotification = ({ show, onClose, message, url=null, delay = 15000, error = false, networkId }) => {
  const [showToast, setShowToast] = useState(show);


  const openScan = (addr) => {
    if(networkId){
    const url = SETTINGS.scanLinks[networkId];
    console.log(url+addr);
    window
      .open(
        url+addr,
        "_blank"
      )
      .focus();
    }
  };

  useEffect(() => {
    setShowToast(show);
    if (show) {
      const timer = setTimeout(() => {
        setShowToast(false);
        onClose();
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [show, delay, onClose]);

  return (
    <Toast style={{zIndex:"999999"}} show={showToast} onClose={() => { setShowToast(false); onClose(); }} delay={delay} autohide className={`${error ? 'toastMessage toastMessageError' : 'toastMessage'}`}>
      <Toast.Body style={{position:"relative" }} >{message}
      <br />
      {url ? (
      <button className='buyButton' style={{position:"absolute", right:10, top: 12 }} onClick={() => openScan(url)}>View tx  </button>
      ):(null)}


<button className='buyButton' style={{position:"absolute", right:-25, top: -12, fontSize:"12px", fontWeight:"bold" }} onClick={() => { setShowToast(false); onClose(); }} ><BiXCircle /> </button>
      </Toast.Body>
    </Toast>
  );
};

export default ToastNotification;
