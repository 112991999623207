import React from 'react';
import styled, { keyframes } from 'styled-components';
import coinImage from '../GlobalToken.svg';

const animateRotate = keyframes`
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const animateBrightness = keyframes`
  0%, 50%, 100% {
    filter: blur(0px) brightness(120%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1)) drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
  25%, 75% {
    filter: blur(1px) brightness(50%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1)) drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
`;

const OuterBox = styled.div`
  transition: all 0.4s ease-out;
  cursor: pointer;
  animation: ${animateBrightness} 2.5s infinite linear;
  width: 100px;
  height: 65px;
  transform: scale(0.4);
  transform-origin: left top;
`;

const InnerBox = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  z-index: 2;
  perspective: 10000px;
  transform-style: preserve-3d;
  transform-origin: center;
  animation: ${animateRotate} 12s infinite linear;
`;

const CoinFace = styled.div`
  background-image: url(${coinImage});
  width: 100%;
  height: 100%;
  position: absolute;
  transform: ${(props) => `translateZ(${props.zIndex}px)`};
`;

const Global = ({ width = 244, height = 244 }) => {
  const layers = [];
  for (let i = -5; i <= 5; i++) {
    layers.push(<CoinFace key={i} zIndex={i} />);
  }

  return (
    <OuterBox>
      <InnerBox width={width} height={height}>
        {layers}
      </InnerBox>
    </OuterBox>
  );
};

export default Global;
