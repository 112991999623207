import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { BiQuestionMark } from 'react-icons/bi';


const TooltipInfoReverse = ({cur = "USDT", title = "Swapping income:"}) => {
  const tooltip = (
    <Tooltip id="tooltip" style={{width:350, display:"block"}}><small className='smallText'>
    <b>{title}</b>


  <ul style={{textAlign:"left"}}>
<li>1 to 99 {cur}: 50%</li>
<li>100 to 249 {cur}: 40%</li>
<li>250 to 749 {cur}: 30%</li>
<li>750 to 999 {cur}: 20%</li>
<li>1000 {cur} & more: 10%</li>


</ul>

    </small></Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip} placement="top">
      <button className="tooltipButton" >
                    <BiQuestionMark />    
                          </button>
    </OverlayTrigger>
  );
};

export default TooltipInfoReverse;