// src/components/FlipNotification.js
import React, { useState, useEffect } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';


const notifications = [
    "📢  Telegram Channel!  📢",
    "🌊 Swaps @ WaveSwaps 🌊",
    "📢  Telegram Group!  📢",
   // "🎁 Airdrop alert! 🎁",
   //"🚀 Mainnet Waveswaps Wallet coming soon 🚀",
    "💬 'X (Twitter)' 💬",

   // "🎁 Airdrop: 🎁",
    "📚 Read the FAQ! 📚",
    "📊 DAO Governance 📊", 
  ];
  

const FlipNotification = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 5000); // Change notification every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Flipper flipKey={index}>
      <Flipped flipId="notification">
        <div className="notification">
          {notifications[index]}
        </div>
      </Flipped>
    </Flipper>
  );
};

export default FlipNotification;
