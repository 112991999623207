import React, { useState, useEffect } from 'react';

const LoadingDots = () => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevCount) => (prevCount % 3) + 1);
    }, 500); // Change dot every 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{width:"20px", display:"inline-block", textAlign:"left"}}>
      {'.'.repeat(dotCount)}
    </span>
  );
};

export default LoadingDots;