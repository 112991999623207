// src/components/admin/AdminContainer.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./Sidebar";
import { Route, Routes } from "react-router-dom";
import StakingWaveComponent from "./StakingWaveComponent";

const AdminContainer = ({ provider, account, isConnected }) => {
  return (
    <Container fluid>
      <Row>
        {/* Sidebar menu */}
        <Col md={3} className="bg-light min-vh-100">
          <Sidebar />
        </Col>

        {/* Main content area */}
        <Col md={9} className="p-4">
          <Routes>
        

<Route path="staking-wave" element={<StakingWaveComponent provider={provider} account={account} isConnected={isConnected} />} />

            {/* Add more routes for other contracts */}
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminContainer;
