const PROFIT_CENTRE = {
    Centres: {
        November: {
            monthName: "November 2024",
            networks: {
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    nativeFEE: "0x000000000000000000000000000000000000",
                    crossChainSwap: "0x000000000000000000000000000000000000",
                    v3GBLbot: "0x000000000000000000000000000000000000",
                    dexWallet: "0x000000000000000000000000000000000000",
                    pricePredictionGame: "0x000000000000000000000000000000000000",
                    liquidityPoolWallet: "0x000000000000000000000000000000000000",
                    ecosystemWallet: "0x000000000000000000000000000000000000",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    nativeFEE: "0x07dDF9A4230A0324317e062F9ccD666E26887Db2",
                    crossChainSwap: "0xC7dF6341CF1708B5CFAe6ea37b2CEc95a4E16DF2",
                    v3GBLbot: "0x1f796ba09eeF5e03c1c114Ba79170C70362cd148",
                    v1GBLbot: "0x5578fDCb9212B3B829dd3986C72e745FDACE3BBc",
                    dexWallet: "0x86D3dA36A85E08741E3D33cdB886E64242B55d3a",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "POL",
                    nativeFEE: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    crossChainSwap: "0x233Ec5995080CFc861B4a71EEe7766b555bDC22e",
                    v3GBLbot: "0x34dFB571c5CE5d91526589103e5782cCcb177f38",
                    v1GBLbot: "0xe7b8583D3A1166E1Bb0d8BB04E65fAB9FaA3E217",
                    stakeGblWave: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    liquidityNodes: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    daoVoting: "0x2BF314D7e43639cE65c9D8faAa61c77C1557952e",
                    dexWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    pricePredictionGame: "0x045725BC613844741fa2050a075b15237E45A23F",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    nativeFEE: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    crossChainSwap: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    v3GBLbot: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    dexWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    tokenDecimals: 18,
                    nativeFEE: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    crossChainSwap: "0x34dFB571c5CE5d91526589103e5782cCcb177f38",
                    v3GBLbot: "0xe7b8583D3A1166E1Bb0d8BB04E65fAB9FaA3E217",
                    dexWallet: "0x233Ec5995080CFc861B4a71EEe7766b555bDC22e",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                }
            }
          },
          October: {
            monthName: "October 2024",
            networks: {
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "33.00123",
                        crossChainSwapBalance: "123.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "POL",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        stakeGblWave: "0.00",
                        liquidityNodes: "0.00",
                        daoVoting: "0.00",
                        dexWalletBalance: "0.00",
                        pricePredictionGame: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    tokenDecimals: 18,
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                },
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        liquidityPoolBalance: "0.00",
                        ecosystemBalance: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
            }
        },
        September: {
            monthName: "September 2024",
            networks: {
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "33.00123",
                        crossChainSwapBalance: "123.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "POL",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        stakeGblWave: "0.00",
                        liquidityNodes: "0.00",
                        daoVoting: "0.00",
                        dexWalletBalance: "0.00",
                        pricePredictionGame: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    tokenDecimals: 18,
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                },
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        liquidityPoolBalance: "0.00",
                        ecosystemBalance: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
            }
        },
        August: {
            monthName: "August 2024",
            networks: {
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "33.00123",
                        crossChainSwapBalance: "123.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "POL",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        stakeGblWave: "0.00",
                        liquidityNodes: "0.00",
                        daoVoting: "0.00",
                        dexWalletBalance: "0.00",
                        pricePredictionGame: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    tokenDecimals: 18,
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                },
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        liquidityPoolBalance: "0.00",
                        ecosystemBalance: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
            }
        },
        July: {
            monthName: "July 2024",
            networks: {
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "33.00123",
                        crossChainSwapBalance: "123.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "POL",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v1GBLbot: "0.00",
                        v3GBLbot: "0.00",
                        stakeGblWave: "0.00",
                        liquidityNodes: "0.00",
                        daoVoting: "0.00",
                        dexWalletBalance: "0.00",
                        pricePredictionGame: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    tokenDecimals: 18,
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        v3GBLbot: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                },
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    // Hard-coded values for October 2024
                    hardCodedValues: {
                        nativeFEE: "0.00",
                        crossChainSwapBalance: "0.00",
                        liquidityPoolBalance: "0.00",
                        ecosystemBalance: "0.00",
                        dexWalletBalance: "0.00",
                    },
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
            }
        },
    },
  };
  
export default PROFIT_CENTRE;
  