const SETTINGS_SUPPLY = {
    Tokens: {
        GlobalToken: {
            tokenName: "GlobalToken",
            networks: {
                ethereum: {
                    totalSupply: "17,900,000",
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    swapWallet: "0x000000000000000000000000000000000000",
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://etherscan.io/address/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",  
                    tokenUrl: "https://etherscan.io/token/0x38E3EA1f41AcceF53274f9544eD678a64C6eBd49",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "17,900,000",
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "17,900,000",
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "17,900,000",
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "17,900,000",
                    apiKey: "K81C68HY4UVV6MYVYMBEY4QM835FNVJY5V",
                    tokenAddress: "0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/gbl.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                }
            }
          },
        CryptoCode: {
            tokenName: "CryptoCode",
            networks: {
                ethereum: {
                    totalSupply: "1,000,000",
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://etherscan.io/address/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",  
                    tokenUrl: "https://etherscan.io/token/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "21,000,000",
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 8,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://bscscan.com/address/0xdc514814ece982fce52d5b5dfd8d22fa652fb942",  
                    tokenUrl: "https://bscscan.com/token/0xdc514814ece982fce52d5b5dfd8d22fa652fb942",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "1,000,000",
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://polygonscan.com/address/0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",  
                    tokenUrl: "https://polygonscan.com/token/0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "1,000,000",
                    apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                    tokenAddress: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://arbiscan.io/address/0x1E2A7824942754c30D8a24C1de8B383d417b804d",  
                    tokenUrl: "https://arbiscan.io/token/0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "1,000,000",
                    apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                    tokenAddress: "0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/code.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x1E2A7824942754c30D8a24C1de8B383d417b804d",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x1E2A7824942754c30D8a24C1de8B383d417b804d",
                    network: 'OP Mainnet'
                }
            }
        },
        MetaCoin: {
            tokenName: "MetaCoin",
            networks: {
                ethereum: {
                    totalSupply: "1,000,000", 
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC", 
                    tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac", 
                    custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                    recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                    tokenDecimals: 18, 
                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://etherscan.io/address/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",  
                    tokenUrl: "https://etherscan.io/token/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                    network: 'Ethereum Mainnet'
                },
                bsc: {
                    totalSupply: "210,000,000", 
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                    tokenAddress: "0xfe1e4b7515b933faaf7b32e569eb245b7542b03c", 
                    custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                    swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                    recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://bscscan.com/address/0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",  
                    tokenUrl: "https://bscscan.com/token/0xfe1e4b7515b933faaf7b32e569eb245b7542b03c",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    totalSupply: "1,000,000", 
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                    tokenAddress: "0xD51E7065B942D5A5886915C6BEbf42600D546162", 
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                    swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                    recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    tokenDecimals: 18, 
                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://polygonscan.com/address/0xD51E7065B942D5A5886915C6BEbf42600D546162",  
                    tokenUrl: "https://polygonscan.com/token/0xD51E7065B942D5A5886915C6BEbf42600D546162",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    totalSupply: "1,000,000",
                    apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                    tokenAddress: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                    recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://arbiscan.io/address/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",  
                    tokenUrl: "https://arbiscan.io/token/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    network: 'Arbitrum One'
                },
                optimism: {
                    totalSupply: "1,000,000",
                    apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                    tokenAddress: "0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                    swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                    recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                    tokenDecimals: 18,
                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/meta.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0xC3fB4Cf6Cff2206f0055f9b5670e5B57c4FF3F94",
                    network: 'OP Mainnet'
                }
            }
        },
        PiplCoin: {
            tokenName: "PiplCoin",
            networks: {
            ethereum: {
                totalSupply: "210,000,000", 
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC", 
                tokenAddress: "0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4", 
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                swapWallet: "0x000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://etherscan.io/address/0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",  
                tokenUrl: "https://etherscan.io/token/0xE64509F0bf07ce2d29A7eF19A8A9bc065477C1B4",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000", 
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                tokenAddress: "0xE12723848699AdcbFF466050cE17Cbf5C6156720", 
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://bscscan.com/address/0xE12723848699AdcbFF466050cE17Cbf5C6156720",  
                tokenUrl: "https://bscscan.com/token/0xE12723848699AdcbFF466050cE17Cbf5C6156720",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000", 
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                tokenAddress: "0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc", 
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://polygonscan.com/address/0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",  
                tokenUrl: "https://polygonscan.com/token/0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://arbiscan.io/address/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",  
                tokenUrl: "https://arbiscan.io/token/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pipl.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x0e1744A5787e8C059FD507FC891ADb7B6334e952",
                network: 'OP Mainnet'
            }
            }
        },
        GetonCoin: {
            tokenName: "GetonCoin",
            networks: {
            ethereum: {
                totalSupply: "1,542,000,000",
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB",
                tokenAddress: "0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://etherscan.io/address/0xF1D353199fdf1F1B8A1ccD641611129137a40aec",  
                tokenUrl: "https://etherscan.io/token/0xF1D353199fdf1F1B8A1ccD641611129137a40aec",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "1,542,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://bscscan.com/address/0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",  
                tokenUrl: "https://bscscan.com/token/0xEAFdb8D33Edb293aA17473f46eB5292BBb4bFb51",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://polygonscan.com/address/0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",  
                tokenUrl: "https://polygonscan.com/token/0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://arbiscan.io/address/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",  
                tokenUrl: "https://arbiscan.io/token/0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x35631d5dD2f50b42Da62f1dd4c7B71DF624041Ac",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/geton.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                network: 'OP Mainnet'
            }
            }
        },
        GroshCoin: {
            tokenName: "GroshCoin",
            networks: {
            ethereum: {
                totalSupply: "210,000,000", 
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB", 
                tokenAddress: "0xd92B3100e19bef7A32aef1451D891f99F9316e13", 
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd", 
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://etherscan.io/address/0xd92B3100e19bef7A32aef1451D891f99F9316e13",  
                tokenUrl: "https://etherscan.io/token/0xd92B3100e19bef7A32aef1451D891f99F9316e13",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000", 
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU", 
                tokenAddress: "0xb67B9a92D902F8beadEe9846CDde40a10F198808", 
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0", 
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://bscscan.com/address/0xb67B9a92D902F8beadEe9846CDde40a10F198808",  
                tokenUrl: "https://bscscan.com/token/0xb67B9a92D902F8beadEe9846CDde40a10F198808",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000", 
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G", 
                tokenAddress: "0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220", 
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95", 
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18, 
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://polygonscan.com/address/0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",  
                tokenUrl: "https://polygonscan.com/token/0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/grosh.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x32c6a5b6bff1a8CBc959b188F5acd6b2118C9271",
                network: 'OP Mainnet'
            }
            }
        },
        PronCoin: {
            tokenName: "PronCoin",
            networks: {
            ethereum: {
                totalSupply: "69,000,000",
                apiKey: "SZFV95TCA2W21G1M4Z8NMJFBM5FFMSWXMB",
                tokenAddress: "0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x00",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://etherscan.io/address/0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",  
                tokenUrl: "https://etherscan.io/token/0xA3149E0fA0061A9007fAf307074cdCd290f0e2Fd",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "69,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://bscscan.com/address/0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",  
                tokenUrl: "https://bscscan.com/token/0xC205F4B106D719e5C2Cc5eB8773c3462b0b78393",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "1,000,000",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://polygonscan.com/address/0x2a6aF8DA13B36F925632a404A3161fE344d8f469",  
                tokenUrl: "https://polygonscan.com/token/0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "1,000,000",
                apiKey: "Y33676XZFD7Y3BKV9A2FXB988M6QR9YK2G",
                tokenAddress: "0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://arbiscan.io/address/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",  
                tokenUrl: "https://arbiscan.io/token/0x25E67201Ed5107e47d5B04dF4FBCe1487b3Ca1A3",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "1,000,000",
                apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                tokenAddress: "0x3692668069fe43771f46EEA1aaa28064debFFA9c",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/pron.svg",  
                networkUrl: "https://optimistic.etherscan.io/address/0x3692668069fe43771f46EEA1aaa28064debFFA9c",  
                tokenUrl: "https://optimistic.etherscan.io/token/0x3692668069fe43771f46EEA1aaa28064debFFA9c",
                network: 'OP Mainnet'
            }
            }
        },
        LifeOnScreen: {
            tokenName: "LifeOnScreen",
            networks: {
            ethereum: {
                totalSupply: "1,125,000,000",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x705794a33b0aCdD9C47aD363c82a45A20056F734",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "https://etherscan.io/address/0x705794a33b0aCdD9C47aD363c82a45A20056F734",  
                tokenUrl: "https://etherscan.io/token/0x705794a33b0aCdD9C47aD363c82a45A20056F734",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "1,000,048",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xc1463935d11EB0C9a06530F387077053Ebe4500B",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "https://bscscan.com/address/0xc1463935d11EB0C9a06530F387077053Ebe4500B",  
                tokenUrl: "https://bscscan.com/token/0xc1463935d11EB0C9a06530F387077053Ebe4500B",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/los.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        CarbCoin: {
            tokenName: "CarbCoin",
            networks: {
            ethereum: {
                totalSupply: "743,100,000",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0xA517a46Baad6B054A76bD19c46844f717fe69fea",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "https://etherscan.io/address/0xA517a46Baad6B054A76bD19c46844f717fe69fea",  
                tokenUrl: "https://etherscan.io/token/0xA517a46Baad6B054A76bD19c46844f717fe69fea",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "743,100,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "https://bscscan.com/address/0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",  
                tokenUrl: "https://bscscan.com/token/0x0C94f658a9EEAE8050B59E8D687a6B7eba84037d",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/carb.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        CryptoBoard: {
            tokenName: "CryptoBoard",
            networks: {
            ethereum: {
                totalSupply: "6,184,089",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "https://etherscan.io/address/0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",  
                tokenUrl: "https://etherscan.io/token/0x4fFa2E6D1ce17A63419315dA9991353772bf7F39",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "6,184,089",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "https://bscscan.com/address/0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",  
                tokenUrl: "https://bscscan.com/token/0x59592AB0Fc7b38625d61E8cB7A3348bD3C00Dc9E",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/cbdx.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        AirPodToken: {
            tokenName: "AirPodToken",
            networks: {
            ethereum: {
                totalSupply: "147,792,670",
                apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                tokenAddress: "0x99bC08DB67F52010f2D6017b7aD968808113dB10",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                swapWallet: "0x000000000000000000000000000000000000",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "https://etherscan.io/address/0x99bC08DB67F52010f2D6017b7aD968808113dB10",  
                tokenUrl: "https://etherscan.io/token/0x99bC08DB67F52010f2D6017b7aD968808113dB10",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "147,792,670",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "https://bscscan.com/address/0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",  
                tokenUrl: "https://bscscan.com/token/0xb0c6c8160e5b63B861C5b163ddd1D06036cF76D9",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/apod.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        eGROSH: {
            tokenName: "eGROSH",
            networks: {
            ethereum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "210,000,000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x542e258550dc85ede56d9480bc6a68c07158ed24",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "https://bscscan.com/address/0x542e258550dc85ede56d9480bc6a68c07158ed24",  
                tokenUrl: "https://bscscan.com/token/0x542e258550dc85ede56d9480bc6a68c07158ed24",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/egrosh.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        },
        GMAAR: {
            tokenName: "GMAAR",
            networks: {
            ethereum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                
                recyclePoolWallet: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                liquidityPoolWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                ecosystemWallet: "0x5bCa87e3261fA4FEEEE6E83012e48849468e108e",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Ethereum Mainnet'
            },
            bsc: {
                totalSupply: "57000000",
                apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                tokenAddress: "0x7A81eeB086e4502bb8A022F804831575db42Fa07",
                custodyWallet: "0x7dB9DC4999D3F55fA824248083fB3E3B4C1806F0",
                swapWallet: "0xA63Cb1F1d6a787a35B5285B709B761917e933642",
                recyclePoolWallet: "0x9c144D62107E7309f28cd2AC9D38dD6e8cF4586D",
                tokenDecimals: 8,
                networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "https://bscscan.com/address/0x7A81eeB086e4502bb8A022F804831575db42Fa07",  
                tokenUrl: "https://bscscan.com/token/0x7A81eeB086e4502bb8A022F804831575db42Fa07",
                network: 'BNB Smart Chain'
            },
            polygon: {
                totalSupply: "0",
                apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                swapWallet: "0x1bc0395366a2a31b95213021D5F3E39160Eff5bA",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Polygon Mainnet'
            },
            arbitrum: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                recyclePoolWallet: "0x045725BC613844741fa2050a075b15237E45A23F",
                swapWallet: "0xA1b63dEABEc09A0A9a710c5768E915c5ac95b66C",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'Arbitrum One'
            },
            optimism: {
                totalSupply: "0",
                apiKey: "#",
                tokenAddress: "0x",
                custodyWallet: "0x61D634c1B1F276b2f11A6B60f120F0097e356C17",
                recyclePoolWallet: "0xA92c62b675dE3D397d38Fdd8aa53629A2A9FAf95",
                swapWallet: "0x5FCa3cfb3B7E54d833648a44422285229df36560",
                tokenDecimals: 18,
                networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                tokenLogo: "https://tokens.waveswaps.com/images/tokens/gmaar.svg",  
                networkUrl: "#",  
                tokenUrl: "#",
                network: 'OP Mainnet'
            }
            }
        }

    },
  };
  
export default SETTINGS_SUPPLY;
  