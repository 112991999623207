import React, { useState, useEffect, useContext } from "react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  isAddress,
} from "ethers";

import SETTINGS from "../SETTINGS";
import WaweSwapsABI from "../abis/WaveV2PolygonABI.json";
import WaweSwapStorageABI from "../abis/WaveV2PolygonStorageABI.json";

import { ERC20_ABI } from "../abis/erc20";
import styled from "@emotion/styled";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import ToastNotification from "./ToastNotification";
import LoadingDots from "./LoadingDots";
import InfoModal from "./InfoModal";
import {
  BiHome,
  BiCopy,
  BiArrowToBottom,
  BiCaretDown,
  BiCaretUp,
} from "react-icons/bi";

import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import TooltipInfoReverseGbl from "./TooltipInfoReverseGbl";

const SpinnerBox = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 15px;
  border: 1px solid var(--maincolor);
  padding: 10px;
  border-radius:100px;
  cursor: pointer;
  width:100%;
  transition: all 0.2s;
  text-align:center;
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = "accordionActive";
  const CLOSED = "accordionClosed";
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED}
      onClick={decoratedOnClick}
    >
      <BiCaretDown style={{ fontSize: 20 }} /> {children}
    </button>
  );
}

function SwapV1({
  provider,
  selectedAccount,
  networkSymbol,
  isConnected,
  networkName,
  networkId,
  switchNetwork,
}) {
  const [stakeAmount, setStakeAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [recBalance, setRecBalance] = useState(0);
  const [ercBalance, setErcBalance] = useState(0);
  const [gblBalance, setGblBalance] = useState(0);
  const [gblAmountValue, setGblAmountValue] = useState(0);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);
  const [swapTokenAmount, setSwapTokenAmount] = useState("");
  const [gblBuyAmount, setGblBuyAmount] = useState("");
  const [gblSellAmount, setGblSellAmount] = useState("");
  const [recycleAmount, setRecycleAmount] = useState("");
  const [refferalAddress, setRefferalAddress] = useState("");
  const [buyTokens, setBuyTokens] = useState([]);
  const [userSwaps, setUserSwaps] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedRecycleToken, setSelectedRecycleToken] = useState(null);
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [stakeSize, setStakeSize] = useState(0);
  const [showStakeCloseModal, setShowStakeCloseModal] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [loadingStep, setLoadingStep] = useState(1);
  const [loadingNumber, setLoadingNumber] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState("Select");
  const [selectedLabelRecycle, setSelectedLabelRecycle] = useState("Select");
  const [showToast, setShowToast] = useState(false);
  const [toastUrl, setToastUrl] = useState(null);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("-");
  const [waveStakes, setWaveStakes] = useState([]);

  const displayToast = (msg, url = null, error = false) => {
    setToastMessage(msg);
    setToastUrl(url);
    setToastError(error);
    setShowToast(true);
  };

  const handleSelect = (token) => {
    setSelectedLabel(`${token.symbol}`);
    handleTokenChange({ target: { value: token.address } });
  };
  const handleSelectRecycle = (token) => {
    setSelectedLabelRecycle(`${token.symbol}`);
    handleRecycleTokenChange({ target: { value: token.address } });
  };

  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };
  const handleShowCollectModal = () => {
    setShowCollectModal(true);
  };

  const handleCloseSwapModal = () => {
    setSwapTokenAmount("");
    setGblBuyAmount(0);
    setGblSellAmount(0);
    setRecycleAmount("");
    setRefferalAddress("");
    setSwapAvailable(false);
    setSelectedRecycleToken(null);
  };

  const getWaveStakes = async (userAddress) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      const userStakes = await contract.getUserWaveStakes(userAddress);
      const stakesWithClaimable = await Promise.all(
        userStakes.map(async (stake, index) => {
          // Fetch claimable amount for each stake
          const claimableAmount = await contract.getClaimableWaveAmount(
            userAddress,
            index
          );
          return {
            amount: formatUnits(stake.amount.toString(), 18),
            startTime: new Date(
              parseInt(stake.startTime.toString()) * 1000
            ).toLocaleString(),
            claimedAmount: formatUnits(stake.claimedAmount.toString(), 18),
            claimableAmount: parseFloat(
              formatUnits(claimableAmount.toString(), 18)
            ).toFixed(6), // Added claimable amount
          };
        })
      );

      setWaveStakes(stakesWithClaimable);
    } catch (error) {
      console.error("Error fetching stakes:", error);
    }
  };
  const claimWaveStake = async (stakeIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      const tx = await contract.claim(stakeIndex,{
        value: 1000000000000000
      });
      await tx.wait();
      alert("Claim successful!");
      await loadUserData();
    } catch (error) {
      console.error("Error claiming stake:", error);
    }
  };

  const closeSwap = async (_swapIndex) => {
    setIsLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      const tx = await contract.openWaveStake(_swapIndex, {
        value: 1000000000000000
      });

      await tx.wait(); // Wait for the transaction to be confirmed
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
      displayToast("Swap closed.", tx.hash);
    } catch (error) {
      console.error("Swap close failed", error);
      setIsLoading(false);
    }
  };

  const getBalance = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const balanceWei = await ethersProvider.getBalance(selectedAccount);
      const balanceEth = formatUnits(balanceWei, "ether");
      return parseFloat(balanceEth).toFixed(6);
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  const getBalanceERC = async (addr) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const TokenContract = new Contract(addr, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(selectedAccount);
      const TokenDecimals = await TokenContract.decimals();
      return parseFloat(formatUnits(TokenBalance, TokenDecimals)).toFixed(6);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };

  useEffect(() => {
    var sp = new URLSearchParams(window.location.search);
    const ref = sp.get("reff");
    setRefferalAddress(ref);
    if (selectedAccount) {
      firstLoad();
      handleSelect({ symbol: "GBL", address: SETTINGS.globalTokenAddress });
    } else {
      setGblBalance(0);
      setUserSwaps([]);
      handleSelect({ symbol: "GBL", address: SETTINGS.globalTokenAddress });
    }
  }, [selectedAccount, buyTokens, networkId]);

  const firstLoad = async () => {
    await loadUserData();
    if (buyTokens.length == 0) {
      showAvailableTokens(selectedAccount);
    }
  };

  const loadUserData = async () => {
    setIsLoading(true);

    try {
      // Load the global balance using ethers.js function
      setGblBalance(await getBalanceERC(SETTINGS.globalTokenAddress));

      // Load swaps for the user
      const swaps = await getSwapsForUser(selectedAccount);

      // Instantiate the contract using ethers.js
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      // Fetch the referral address
      const myRefferer = await contract.referrals(selectedAccount);

      if (myRefferer !== "0x0000000000000000000000000000000000000000") {
        setRefferalAddress(myRefferer);
      }
      const wawe_stakes = await getWaveStakes(selectedAccount);
      setUserSwaps(swaps);
    } catch (error) {
      console.error("Error loading user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSwapsForUser = async (userAddress) => {
    try {
      // Initialize the ethers provider and signer
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Instantiate contracts with ethers
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const contractStorage = new Contract(
        SETTINGS.waweSwapPolygonStorageAddress,
        WaweSwapStorageABI,
        signer
      );

      // Fetch swaps and rewards data
      const userSwaps = await contractStorage.getSwaps(userAddress);
      const userRewardAmountWei = await contractStorage.rewards(userAddress);

      // Process swaps and convert reward amount from wei
      let swaps = [];
      let userRewardAmount = parseFloat(
        formatUnits(userRewardAmountWei, "ether")
      );

      for (let i = 0; i < userSwaps.length; i++) {
        const mxSellAmount = parseFloat(
          formatUnits(userSwaps[i][2], "ether")
        ).toFixed(4);
        const swapIndex = parseInt(userSwaps[i][5]);

        const temp = {
          uid: i,
          swapIndex: swapIndex,
          amount: parseFloat(formatUnits(userSwaps[i][0], "ether")).toFixed(2),
          sellAmount: parseFloat(formatUnits(userSwaps[i][1], "ether")).toFixed(
            4
          ),
          mxSellAmount: mxSellAmount,
          maxSellAmount: parseFloat(
            formatUnits(userSwaps[i][2], "ether")
          ).toFixed(4),
          soldAmount: (
            parseFloat(formatUnits(userSwaps[i][2], "ether")) -
            parseFloat(formatUnits(userSwaps[i][1], "ether"))
          ).toFixed(4),
        };
        swaps.push(temp);
      }

      // Calculate rewards with fee logic
      setUnclaimedRewards(userRewardAmount.toFixed(4));
      let feePercentage =
        userRewardAmount < 100 ? 0.02 : userRewardAmount < 1000 ? 0.01 : 0.005;
      let feeAmount = userRewardAmount * feePercentage;
      let netRewardAmount = userRewardAmount - feeAmount;
      setUnclaimedRewardsFee(feeAmount.toFixed(4));
      setUnclaimedRewardsNet(netRewardAmount.toFixed(4));

      return swaps.reverse();
    } catch (error) {
      console.error("Error fetching swap data:", error);
    }
  };

  const handleTokenChange = async (e) => {
    try {
      const selectedAddress = e.target.value;
      if (selectedAddress === "") {
        setSelectedToken(null);
        setSelectDisabled(false);
      } else {
        const token = buyTokens.find(
          (token) => token.address === selectedAddress
        );
        setSelectedToken(token);
        if (token == undefined) return;
        if (token.symbol === "BNB") {
          setErcBalance(await getBalance());
        } else {
          setErcBalance(await getBalanceERC(token.address));
        }
        setSwapTokenAmount("");
        setGblBuyAmount(0);
        setGblSellAmount(0);
        setSelectDisabled(false);
      }
      setSwapAvailable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecycleTokenChange = async (e) => {
    await functionRecycleTokenChange(e.target.value);
  };

  const functionRecycleTokenChange = async (selectedAddress) => {
    if (selectedAddress === "") {
    } else {
      const token = tokens.find((token) => token.address === selectedAddress);
      if (token == undefined) return;
      setSelectedRecycleToken(token);

      setSwapAvailable(true);
    }
  };

  const handleRefferalAddressChange = async (e) => {
    const addr = e.target.value;

    if (addr.length === 0) return;

    // Validate address with ethers.js
    if (!isAddress(addr)) {
      displayToast("Wrong address type", null, true);
      setRefferalAddress("");
      return;
    }

    if (addr.toLowerCase() === selectedAccount.toLowerCase()) {
      displayToast("You can't use your own address.", null, true);
      setRefferalAddress("");
      return;
    }

    setRefferalAddress(addr);
  };

  const handleBuyAmountChange = async (e) => {
    const amount = e.target.value;
    setSwapTokenAmount(amount);

    setGblBuyAmount(0);
    setGblSellAmount(0);
    setRecycleAmount(0);

    if (amount === "") {
      setSwapAvailable(false);
    } else {
      if (parseFloat(amount) > parseFloat(ercBalance)) {
        setErrorText("Balance to low!");
        setGblBuyAmount(0);
        setGblSellAmount(0);
        setSwapAvailable(false);
      } else {
        setErrorText("");

        let feePercentage = 1.5;
        if (parseFloat(amount) < 10) {
          feePercentage = 2;
        } else if (parseFloat(amount) >= 10 && parseFloat(amount) < 20) {
          feePercentage = 1.9;
        } else if (parseFloat(amount) >= 20 && parseFloat(amount) < 30) {
          feePercentage = 1.8;
        } else if (parseFloat(amount) >= 30 && parseFloat(amount) < 40) {
          feePercentage = 1.7;
        } else if (parseFloat(amount) >= 40 && parseFloat(amount) < 50) {
          feePercentage = 1.7;
        } else if (parseFloat(amount) >= 50) {
          feePercentage = 1.5;
        }

        const swapBuySellAmount = parseFloat(amount) * feePercentage;
        setGblBuyAmount(swapBuySellAmount.toFixed(4));
        setGblSellAmount(swapBuySellAmount.toFixed(4));
        setSwapAvailable(true);
      }
    }
  };
  const collectIncome = async () => {
    setIsLoading(true);

    try {
      // Initialize ethers provider and signer
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Instantiate the contract
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      // Send the transaction to claim rewards
      const transaction = await contract.claimReward({
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      await transaction.wait(); // Wait for transaction confirmation

      // Reset stake and rewards state
      setStakeAmount("");
      setUnclaimedRewards(0);
      setUnclaimedRewardsNet(0);
      setUnclaimedRewardsFee(0);
    } catch (error) {
      console.error("Claim reward failed:", error);
    } finally {
      // Reload user data and close modal after completion
      await loadUserData();
      handleCloseCollectModal();
      setIsLoading(false);
    }
  };

  const copyRefferalLink = async () => {
    const text = "https://waveswaps.com/?v=2&reff=" + selectedAccount;
    navigator.clipboard.writeText(text);
    displayToast("Link coppied!");
  };
  const handleSwap = async () => {
    setIsLoading(true);

    try {
      // Initialize ethers provider and signer
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Instantiate the main contract
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );
      const fee = await contract.FEE(); // Get the fee

      // Determine referral address
      let refAddr = refferalAddress
        ? refferalAddress
        : SETTINGS.genessisAddress;

      setLoadingNumber(1);
      setLoadingStep(1);
      setLoadingText(`Approving ${selectedToken.symbol} transaction.`);

      // Instantiate token contract for approval
      const tokenContract = new Contract(
        selectedToken.address,
        ERC20_ABI,
        signer
      );

      // Convert swap amount to Wei and check allowance
      const amountInWei = parseUnits(swapTokenAmount.toString(), "ether");
      const allowanceToken = await tokenContract.allowance(
        selectedAccount,
        SETTINGS.waweSwapPolygonAddress
      );

      // Approve token if needed

        setLoadingNumber(2);
        const approveTx = await tokenContract.approve(
          SETTINGS.waweSwapPolygonAddress,
          amountInWei,
          {
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          }
        );
        await approveTx.wait(); // Wait for approval confirmation
        setLoadingStep(2);
      

      setLoadingText("Starting V1 GBL Bot");

      // Execute buySwap transaction
      const tx = await contract.buySwap(
        selectedRecycleToken.address,
        amountInWei,
        refAddr,
        {
          value: fee,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        }
      );
      await tx.wait(); // Wait for transaction confirmation

      displayToast("V1 GBL bot started!", tx.hash);
    } catch (error) {
      console.error("GBL failed:", error);
    } finally {
      // Reload user data, close modal, and update UI
      await loadUserData();
      handleCloseSwapModal();
      setIsLoading(false);
    }
  };

  const handleCloseSwap = async (index) => {
    setIsLoading(true);
    setLoadingNumber(1);
    setLoadingStep(1);
    setLoadingText("Closing swap.");

    await handleCloseSwapFunction(index);

    setIsLoading(false);
    loadUserData();
  };

  const handleCloseSwapFunction = async (index) => {
    try {
      // Initialize ethers provider and signer
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Instantiate contract
      const contract = new Contract(
        SETTINGS.waweSwapPolygonAddress,
        WaweSwapsABI,
        signer
      );

      // Call closeSwap function on the contract
      const withdrawTx = await contract.closeSwap(index, {
        value: 0,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });

      await withdrawTx.wait(); // Wait for transaction confirmation
      displayToast("Swap closed!", withdrawTx.hash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
  };

  const showAvailableTokens = (address) => {
    // Construct the URL with the address and amount parameters
    const url = `https://waweswaps.global/api/get_balances.php?address=${address}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.text();
      })
      .then((data) => {
        var tokenArr = JSON.parse(data);
        var userTokens = [];

        for (let i = 0; i < tokenArr.length; i++) {
          userTokens.push(tokenArr[i].symbol);
        }

        var displayTokens = [];
        for (let i = 0; i < SETTINGS.tokensDataPolygon.length; i++) {
          if (userTokens.includes(SETTINGS.tokensDataPolygon[i].symbol)) {
            displayTokens.push(SETTINGS.tokensDataPolygon[i]);
          }
        }
        setTokens(
          SETTINGS.tokensDataPolygon.sort((a, b) =>
            a.price > b.price ? -1 : 1
          )
        );
        setBuyTokens([
          { name: "GBL", symbol: "GBL", address: SETTINGS.globalTokenAddress },
        ]);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const exportSwaps = async () => {
    // Set up provider and contract
    const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

    
    const contractStorage = new Contract(SETTINGS.waweSwapPolygonStorageAddress, WaweSwapStorageABI, signer);
    const contract = new Contract(
      SETTINGS.waweSwapPolygonAddress,
      WaweSwapsABI,
      signer
    );
    // Call the function to get all open swaps
    let allOpenSwaps = await contractStorage.getAllOpenSwaps();
    let csvResult = "addr;sellAmount;startValue;swapAmount;userRewardAmount\n";
    let swapsArr = [];
    let usersArr = [];
    let fullAmount = 0;
    let stakesArrUsers = [];
    let stakesArrAmounts = [];
    let stakesArrStartTimes = [];
    let stakesArrClaimed = [];
    for (let i = 0; i < allOpenSwaps.length; i++) {
      try{
      let swap = allOpenSwaps[i];
      if (swap[3] === "0x0000000000000000000000000000000000000000") continue;
      
      const swapAmount = formatUnits(swap[0], "ether");
      const sellAmount = formatUnits(swap[1], "mwei");
      const startValue = formatUnits(swap[2], "mwei");
      const addr = swap[3];
      console.log(addr);
      
     
      if(!usersArr.includes(addr)){
        usersArr.push(addr);
        const userStakes = await contract.getUserWaveStakes(addr);
        console.log(userStakes);
        console.log(userStakes.length);
        
          userStakes.map(async (stake, index) => {
            // Fetch claimable amount for each stake
            stakesArrUsers.push(addr);
            stakesArrAmounts.push(stake.amount.toString());
            stakesArrStartTimes.push(stake.startTime.toString());
            stakesArrClaimed.push(stake.claimedAmount.toString());
           
          });
        
        
        /*
      const userRewardAmountWei = await contractStorage.usdtRewards(addr);

      let tokenDecimals = parseInt(networkId) === 56 ? 18 : 6;
      const userRewardAmount = parseFloat(
        formatUnits(
          userRewardAmountWei,
          tokenDecimals === 18 ? "ether" : "mwei"
        )
      );
      
      fullAmount += userRewardAmount;
      
      console.log(userRewardAmountWei);
      console.log(userRewardAmount);
      console.log(fullAmount);
      swapsArr.push([swapAmount, sellAmount, startValue, addr,userRewardAmount]);
  
      csvResult += `${addr};${sellAmount};${startValue};${swapAmount};${userRewardAmount}\n`;
     */
      }
    }catch (error) {
      console.error("wrong err", error);
    }
    }
    console.log(stakesArrUsers);
    console.log(stakesArrAmounts);
    console.log(stakesArrStartTimes);
    console.log(stakesArrClaimed);
    // Log swaps array
    //console.log("swapsArr", JSON.stringify(swapsArr));
  
    // Generate CSV file and download it
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV3_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };




  const exportSwaps1 = async () => {
    /*
    const contractStorage = new web3.eth.Contract(
      WaweSwapStorageABI,
      SETTINGS.waweSwapPolygonStorageAddress
    );

    let allOpenSwaps = await contractStorage.methods.getAllOpenSwaps().call();
    let csvResult = "";
    let swapsArr = [];

    for (let i = 0; i < allOpenSwaps.length; i++) {
      let swap = allOpenSwaps[i];
      if (swap[3] == "0x0000000000000000000000000000000000000000") continue;
      const swapAmount = swap[0];
      const sellAmount = swap[1];
      const startValue = swap[2];
      const addr = swap[3];
      swapsArr.push([swapAmount, sellAmount, startValue, addr]);

      csvResult +=
        addr +
        ";" +
        parseFloat(web3.utils.fromWei(sellAmount, "ether")).toFixed(6) +
        ";" +
        parseFloat(web3.utils.fromWei(startValue, "ether")).toFixed(6) +
        ";" +
        parseFloat(web3.utils.fromWei(swapAmount, "ether")).toFixed(6) +
        "\n";
    }

    console.log("swapsArr");
    console.log(JSON.stringify(swapsArr));

    // generate csv file
    console.log(JSON.stringify(swapsArr));
    const currentDateTime = getCurrentDateTimeString();
    const fileName = `swapV2_export_${currentDateTime}.csv`;
    const blob = new Blob([csvResult], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    */
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
   
};


  if (networkId != "137" && !SETTINGS.IS_TEST) {
    return (
        <div className=" text-center">
            <div className="network-switch-section" style={{marginTop:"150px"}}>
             
                  <p>Switch to Polygon network:</p>
                  <Button className="refresh-button hero-button " variant="primary" onClick={handleSwitchNetwork}>
                    Switch to Polygon Network</Button>
                </div>
        </div>
    );
}

  return (
    <div
      className="waweSwap page-container"
      style={{ marginBottom: "100px", marginTop: "50px" }}
    >


      <ToastNotification
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        url={toastUrl}
        error={toastError}
      />

      {isLoading ? (
        <center>
          <br />{" "}
          <Spinner animation="border" role="status" className="loaderBig" />
          <p className="smallTitle">
            Step <br />
            <b>{loadingStep} </b> of <b> {loadingNumber}</b> <br />{" "}
            {loadingText} <LoadingDots />
            <br />
          </p>
        </center>
      ) : (
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 ">
            {selectedAccount && (
              <>
                {selectedAccount.toLowerCase() ===
                  SETTINGS.ownerAddress.toLowerCase() && (
                  <button onClick={exportSwaps}>Export</button>
                )}
              </>
            )}

            <div className="card">
              <div className="card-body text-center">
                <form>
                  {isLoading ? (
                    <div>
                      <center>
                        <p>
                          <b>
                            {loadingStep} / {loadingNumber}
                          </b>{" "}
                          <br /> {loadingText}
                        </p>
                      </center>
                    </div>
                  ) : (
                    <div>
                            <InfoModal
        link="https://docs.waveswaps.com/products-and-features/token-revamp"
        message="Developed from the Swap V1, this innovative mechanism allows users to leverage GBL from the selected network as liquidity when revitalizing tokens from decentralized exchanges (DEX) and allocating them to the Recycle pool. This process not only improves GBL liquidity but also reduces the supply of inactive tokens, increasing their value. Users can now claim remaining balances through WaveCoin. However, this is an additional, optional feature. If not chosen, swaps will continue processing as set innitially."
      />
                      <h4>Recycle Tokens through GBL</h4>
                      {selectedAccount && (
                        <div
                          className="bot-card-header"
                          style={{
                            width: "100%",
                            backgroundColor: "#7da7f0",
                            padding: "20px",
                          }}
                        >
                          <label
                            className="form-label"
                            style={{ color: "#FFFFFF" }}
                          >
                            Earn 10% from referrals' swaps!
                          </label>
                          <button
                            className="mediumButton"
                            type="button"
                            onClick={copyRefferalLink}
                            style={{ float: "right", marginTop: "15px" }}
                          >
                            <BiCopy
                              className="smallIconGray"
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            />
                            Copy{" "}
                          </button>
                        </div>
                      )}
                      <div className="pokemon-card">
                        <label className="form-label">
                          Liquidity Provision
                        </label>
                        <div className="row">
                          <div className="col-6 col-md-8 col-lg-8">
                            <input
                              id="tokenAmount"
                              type="text"
                              className="form-control"
                              placeholder="0"
                              value={swapTokenAmount}
                              onChange={handleBuyAmountChange}
                            />
                            <label className="form-label">
                              <small>Balance: </small> {ercBalance}
                            </label>
                          </div>
                          <div className="col-6 col-md-4 col-lg-4">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="tokenSelect"
                                disabled={selectDisabled}
                                className="dropdownStyle"
                              >
                                <img
                                  src={SETTINGS.tokenIcons[selectedLabel]}
                                  alt=""
                                  style={{ width: "20px", marginRight: "10px" }}
                                />
                                {selectedLabel}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="dropdownMenuStyle">
                                {buyTokens.map((token) => (
                                  <Dropdown.Item
                                    key={token.address}
                                    onClick={() => handleSelect(token)}
                                  >
                                    <img
                                      src={SETTINGS.tokenIcons[token.symbol]}
                                      alt=""
                                      style={{
                                        width: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    {token.symbol}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <center>
                          <i className="smallIconGray">
                            <BiArrowToBottom />
                          </i>
                        </center>
                      </div>

                      <div className="mb-3">
                        <div className="pokemon-card">
                          <div className="row">
                            <div className="col-6 col-md-6 col-lg-6">
                              <label className="form-label">
                                Referral address: <small>(Optional)</small>
                              </label>
                              <input
                                id="refferalAddress"
                                type="text"
                                className="form-control"
                                placeholder="0x.. (Optional)"
                                value={refferalAddress}
                                onChange={handleRefferalAddressChange}
                                style={{ fontSize: "15px" }}
                              />
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                              <label className="form-label">
                                Revamp token:
                              </label>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary"
                                  id="tokenSelect"
                                  disabled={selectDisabled}
                                  className="dropdownStyle"
                                >
                                  <img
                                    src={
                                      SETTINGS.tokenIcons[selectedLabelRecycle]
                                    }
                                    alt=""
                                    style={{
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  {selectedLabelRecycle}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdownMenuStyle">
                                  {tokens.map((token) => (
                                    <Dropdown.Item
                                      key={token.address}
                                      onClick={() => handleSelectRecycle(token)}
                                    >
                                      <img
                                        src={SETTINGS.tokenIcons[token.symbol]}
                                        alt=""
                                        style={{
                                          width: "20px",
                                          marginRight: "10px",
                                        }}
                                      />
                                      {token.symbol}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>

                        {selectedRecycleToken && (
                          <>
                            <div className="pokemon-card">
                              <small className="smallTitle">You send</small>
                              <br />
                              <br />
                              <div className="row">
                                <div className="col-1">
                                  <img
                                    src={
                                      SETTINGS.tokenIcons[selectedToken.symbol]
                                    }
                                    alt=""
                                    style={{ width: "15px" }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="form-label">
                                    To liquidity:{" "}
                                  </label>
                                </div>
                                <div className="col-6">
                                  <label className="form-label">
                                    {" "}
                                    {swapTokenAmount}{" "}
                                    <small>{selectedToken.symbol}</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="pokemon-card">
                              <small className="smallTitle">Target exit</small>
                              <br />
                              <br />

                              <div className="row">
                                <div className="col-1">
                                  <img
                                    src={SETTINGS.tokenIcons["GBL"]}
                                    alt=""
                                    style={{ width: "15px" }}
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="form-label">
                                    Income:{" "}
                                    <TooltipInfoReverseGbl
                                      cur="GBL"
                                      title="Bot income:"
                                    />{" "}
                                  </label>
                                </div>
                                <div className="col-6">
                                  <label className="form-label">
                                    {" "}
                                    {gblSellAmount} <small>GBL</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  <br />
                  <div className="d-grid gap-2" style={{textAlign:"left"}}>
                    <small
                      className="smallText text-left"
                      style={{ paddingBottom: "10px" }}
                    >
                      The referral address used for the first time will remain
                      permanently linked to your current wallet.
                      <br />
                      When a referral address is not linked or added, the swap
                      will automatically use and allocate the Genesis referral
                      address.
                      <br />
                      By clicking the button "Start V1 GBL Bot", you agree with
                      WaveSwaps{" "}
                      <a
                        href="https://docs.waveswaps.com/corporate/terms-and-conditions"
                        target="_blank"
                      >
                        Terms and Conditions.
                      </a>
                    </small>
                  </div>
                  <div className="d-grid gap-2" style={{textAlign:"left"}}>
                    {swapAvailable ? (
                      <div>
                        {isLoading ? (
                          <SpinnerBox>
                            <Spinner animation="border" variant="primary" />
                          </SpinnerBox>
                        ) : (
                          <button
                            className="large-button-blue"
                            type="button"
                            onClick={handleSwap}
                          >
                            Start V1 GBL Bot
                          </button>
                        )}
                      </div>
                    ) : (
                      <div>
                        <button
                          className="large-button-blue-disabled"
                          type="button"
                        >
                          Start V1 GBL Bot
                        </button>
                        <center>
                          <div className="error-text error-text">
                            {errorText}
                          </div>
                        </center>
                      </div>
                    )}

                    <small className="smallText text-left">
                      Swapping income is distributed when enough total native
                      currency has accumulated to execute the distribution
                      function within the V1 GBL Bot smart contract.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <hr />
            <br />
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-3 col-lg-4">
                <small className="smallTitle">Income:</small>
              </div>
              <div className="col-6 col-lg-4">
                <img
                  src={SETTINGS.tokenIcons["GBL"]}
                  alt=""
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                />
                <small> {unclaimedRewards}</small>{" "}
                <small className="smallText">GBL</small>
              </div>
              <div className="col-3 col-lg-4">
                <button
                  type="button"
                  className="hero-button"
                  style={{ marginTop: "0px" }}
                  onClick={handleShowCollectModal}
                >
                  Claim
                </button>
              </div>
            </div>
            <div>
              {waveStakes.length > 0 ? (
                <>
    <h5 className="mb-3 mt-5">WAVE Claim:</h5>
    <div className="col-12">
      <small className="smallText text-left">
      Your list of migrated and canceled V1 GBL Bots, releasing a fixed, proportional WAVE amount daily over 90 days.
      </small><br /><br />
    </div>
                  <Accordion defaultActiveKey="0">
                    {waveStakes.map((stake, index) => (
                      <Card key={index}>
                        <Card.Header>
                          <div className="row">
                            <div className="col-5">
                              <small
                                className="smallTitleSmall"
                                style={{ lineHeight: "30px", marginTop: "5px" }}
                              >
                                <img
                                  src={SETTINGS.tokenIcons["WAVE"]}
                                  alt=""
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                    marginTop: "-3px",
                                  }}
                                />{" "}
                                {stake.amount} WAVE
                              </small>
                            </div>
                            <div className="col-5">
                              <small
                                className="smallTitleSmall"
                                style={{ lineHeight: "30px", marginTop: "5px" }}
                              >
                                <img
                                  src={SETTINGS.tokenIcons["WAVE"]}
                                  alt=""
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                    marginTop: "-3px",
                                  }}
                                />{" "}
                                Claimable: {stake.claimableAmount} WAVE
                              </small>
                            </div>
                            <div className="col-1">
                              <ContextAwareToggle eventKey={String(index)} />
                            </div>
                          </div>
                        </Card.Header>

                        <Accordion.Collapse eventKey={String(index)}>
                          <Card.Body>
                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <label className="form-label">Start Time</label>
                                <br />
                                <small>{stake.startTime}</small>
                              </div>
                              <div className="col-12 col-lg-6">
                                <label className="form-label">
                                  Claimed Amount
                                </label>
                                <br />
                                <small>{stake.claimedAmount} WAVE</small>
                              </div>
                              <div className="col-12">
                                {parseFloat(stake.claimableAmount) > 0 && (
                                  <button
                                    type="button"
                                    className="hero-button"
                                    onClick={() => claimWaveStake(index)}
                                    style={{ marginTop: "10px" }}
                                  >
                                    Claim WAVE
                                  </button>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </div>
            <h5 className="mb-3 mt-5">V1 GBL Bot(s):</h5>
    <div className="col-12">
      <small className="smallText text-left">
      Your active V1 GBL Bots, designed to maximize liquidity and streamline token recycling through automated processes.
      </small><br /><br />
    </div>
            <Accordion defaultActiveKey="0">
              {userSwaps.map((swap, index) => (
                <Card key={index}>
                  <Card.Header>
                    <div className="row ">
                      <div className="col-5">
                        <small
                          className="smallTitleSmall"
                          style={{ lineHeight: "30px", marginTop: "5px" }}
                        >
                          {" "}
                          <img
                            src={SETTINGS.tokenIcons["GBL"]}
                            alt=""
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              marginTop: "-3px",
                            }}
                          />{" "}
                          {swap.soldAmount}{" "}
                          <small className="smallText"> GBL</small>
                        </small>
                      </div>

                      <div className="col-1"></div>
                      <div className="col-5">
                        <small
                          className="smallTitleSmall"
                          style={{ lineHeight: "30px", marginTop: "5px" }}
                        >
                          {" "}
                          <img
                            src={SETTINGS.tokenIcons["GBL"]}
                            alt=""
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              marginTop: "-3px",
                            }}
                          />
                          {swap.mxSellAmount}{" "}
                          <small className="smallText"> GBL</small>{" "}
                        </small>
                      </div>
                      <div className="col-1">
                        {" "}
                        <ContextAwareToggle
                          eventKey={String(index)}
                        ></ContextAwareToggle>
                      </div>

                      <div className="col-12">
                        <ProgressBar
                          now={swap.soldAmount}
                          min={0}
                          max={swap.maxSellAMount}
                          striped
                          variant="info"
                          animated
                          className="swapProgress"
                        ></ProgressBar>
                      </div>
                    </div>
                  </Card.Header>

                  <Accordion.Collapse eventKey={String(index)}>
                    <Card.Body>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <label className="form-label">Swapping </label>
                          <br />
                          <small>
                            {" "}
                            <img
                              src={SETTINGS.tokenIcons["GBL"]}
                              alt=""
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                            {swap.amount} GBL
                          </small>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 col-lg-4">
                          <label className="form-label">Outstanding </label>
                          <br />
                          <small>
                            {" "}
                            <img
                              src={SETTINGS.tokenIcons["GBL"]}
                              alt=""
                              style={{ width: "15px", marginRight: "10px" }}
                            />
                            {swap.sellAmount} GBL
                          </small>
                        </div>
                        <div className="col-12 col-lg-4">
                          <label className="form-label">Migrate to WAVE </label>
                          <br />
                          <button
                            type="button"
                            className="claimButton"
                            style={{ marginTop: "0px" }}
                            onClick={() => closeSwap(swap.swapIndex)} // Wrap in an anonymous function
                          >
                            Close swap and claim {parseFloat(swap.sellAmount) / 20} WAVE through 100 days.
                          </button>
                          <br />
                          <br />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 col-lg-6 text-center">
          <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                <center>Claim income</center>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card ">
                <div className="card-body text-center">
                  <strong>Available GBL income:</strong>

                  <input type="text" value={unclaimedRewards} disabled />
                  <strong>Amount to claim:</strong>

                  <input type="text" value={unclaimedRewardsNet} disabled />
                  <strong>Claim income processing fee:</strong>

                  <input type="text" value={unclaimedRewardsFee} disabled />

                  <br />

                  <br />
                  {unclaimedRewards > 0 ? (
                    <>
                      {isLoading ? (
                        <SpinnerBox>
                          <Spinner animation="border" variant="primary" />
                        </SpinnerBox>
                      ) : (
                        <button
                          className="large-button-blue"
                          type="button"
                          onClick={collectIncome}
                        >
                          Claim
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <br />
              <small className="smallText">
                <b> Swapping income claiming fee requests:</b>
                <ul>
                  <li>Up to 20 GBL: 2%</li>
                  <li>From 21 to 100 GBL: 1%</li>
                  <li>101 GBL onward: 0.5%</li>
                </ul>
              </small>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SwapV1;
