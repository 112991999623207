import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import SETTINGS from '../../SETTINGS';
import WeightedProposalForm from '../dao/WeightedProposalForm';
import WeightedProposalList from '../dao/WeightedProposalList';


const WeightedVoting = ({ provider, selectedAccount, isConnected }) => {
    const [activeTab, setActiveTab] = useState('list');



    useEffect(() => {
        if (!provider) return;

    }, [provider]);

    return (
        <div className='mt-5'>
            <h3 className='pageTitle'>Weighted Voting</h3>
            <p className="hero-p sub-header">
            Amplify your influence by submitting proposals and locking DAO tokens – the more you lock, the stronger your vote.
        </p>

        <Tabs
          id="standard-voting-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="form" title="Submit Proposal">
            <div className="full-width-tab-submitProp">
              <WeightedProposalForm
                provider={provider}
                selectedAccount={selectedAccount}
                isConnected={isConnected}
                setActiveTab={setActiveTab}
              />
            </div>
          </Tab>
          
          <Tab eventKey="list" title="View Proposals">
            <div className="full-width-tab-contentProp">
              <WeightedProposalList
                provider={provider}
                selectedAccount={selectedAccount}
                isConnected={isConnected}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
};

export default WeightedVoting;