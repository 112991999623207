import React from 'react';

const Modal = ({ onClose }) => {
    return (
      <div className="modal-overlay">
  <div className="modal-content">
    <p className="chat-message-modal pageTitle-modal">WaveSwaps<br/>
      <span className="modal-p">Redefining Liquidity and Sustainability in DeFi</span>
    </p>
    <p className="pageModal">
      <span className="modal-content-header">Welcome to WaveSwaps</span><br/>
      Inspired by nature’s cyclical processes, WaveSwaps introduces a groundbreaking approach to liquidity through token recycling. Just as ecosystems repurpose resources to sustain life, WaveSwaps recycles idle tokens to maximize liquidity and sustainability in DeFi...
    </p>
    <button onClick={onClose}>Enter the Platform</button>
    <p className="pageModal">
      <span className="modal-content-header">New to WaveSwaps?</span><br/>
      Start by exploring our FAQ and Documentation to understand our platform’s features and processes.
    </p>
    <p className="pageModal">
      <span className="modal-content-header">Testing - Training Version</span><br/>
      Ready to try before you commit? Experience WaveSwaps risk-free in our Testing & Training Version, where you can explore all platform features using test tokens. Dive in and start building liquidity confidently!
    </p>
    <button onClick={onClose}>Enter the Platform</button>
    <br />
    <br />
    <br />
    <br />
  </div>
</div>

  );
};

export default Modal;
